import React, { useState } from "react";
import api from "../api";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = async () => {
    try {
      setMessage(""); // Clear any existing messages
      setError(""); // Clear any existing errors

      const response = await api.post("/api/forgot-password", { email });
      setMessage(response.data.message || "Password reset link sent to your email.");
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setError(error.response.data.error);
      } else {
        setError("Error sending password reset link. Please try again.");
      }
    }
  };

  return (
    <div className="flex justify-center min-h-screen bg-stone-200">
      <div className="p-6 rounded-lg w-full max-w-sm">
        <div className="mb-4">
          <label className="block font-bold text-sm mb-2">EMAIL ADDRESS</label>
          <input
            type="email"
            placeholder="Enter your email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            required
          />
        </div>
        <div className="flex items-center justify-center">
          <button onClick={handleSubmit} className="text-white bg-black font-medium text-sm px-5 py-2.5 rounded">
            Submit
          </button>
        </div>
        {(message || error) && (
          <div className="mt-4 text-center">
            <p className={error ? "text-red-400" : "text-green-400"}>{error || message}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
