import React from "react";
import image1 from "../assets/TrialSection/trial1.png";
import image2 from "../assets/TrialSection/trial2.png";
import image3 from "../assets/TrialSection/trial3.png";

const TrialSection = () => {
  return (
    <div className="bg-stone-200 py-8 md:py-20 px-8">
      <h2 className="text-2xl md:text-3xl font-bold text-center pt-10 md:pt-0 pb-6">COMMIT NOW FOR JUST $100</h2>

      <h2 className="text-lg md:text-xl font-bold text-center mb-10 font-abel">
        What To Expect At Your First Trial Session
      </h2>
      <div className="flex flex-col md:flex-row justify-center items-stretch space-y-6 md:space-y-0 md:space-x-8 mb-16 font-abel text-justify">
        {/* Card 1 */}
        <div className="bg-stone-100 shadow-lg rounded-lg p-6 md:p-8 flex-1 transition-transform duration-300 transform hover:scale-105 hover:bg-gray-200">
          {" "}
          <img src={image1} alt="Step 1 Image" className="w-full h-48 object-cover mb-4 rounded-md" />
          <h3 className="text-xl md:text-2xl font-bold mb-4">Step 1</h3>
          <h3 clasName="text-xl">Comprehensive Consultation and Body Analysis</h3>
          <p className="mt-4 text-base text-gray-700">
            We'll start with a thorough consultation to understand your fitness goals, health history, and current
            fitness level. We conduct a detailed body analysis to create a baseline and identify areas of focus.
          </p>
        </div>

        {/* Card 2 */}
        <div className="bg-stone-100 shadow-lg rounded-lg p-6 md:p-8 flex-1 transition-transform duration-300 transform hover:scale-105 hover:bg-gray-200">
          {" "}
          <img src={image2} alt="Step 2 Image" className="w-full h-48 object-cover mb-4 rounded-md" />
          <h3 className="text-xl md:text-2xl font-bold mb-4">Step 2</h3>
          <h3 clasName="text-xl">Practical Session</h3>
          <p className="mt-4 text-base text-gray-700">
            Dive straight into a practical session based on our initial understanding to see if the exercises and
            training style are right for you. This hands-on approach helps us assess your fitness level and tailor the
            program to your needs.
          </p>
        </div>

        {/* Card 3 */}
        <div className="bg-stone-100 shadow-lg rounded-lg p-6 md:p-8 flex-1 transition-transform duration-300 transform hover:scale-105 hover:bg-gray-200">
          {" "}
          <img src={image3} alt="Step 3 Image" className="w-full h-48 object-cover mb-4 rounded-md" />
          <h3 className="text-xl md:text-2xl font-bold mb-4">Step 3</h3>
          <h3 clasName="text-xl">Post-Session Analysis and Planning</h3>
          <p className="mt-4 text-base text-gray-700">
            After the trial lesson, we'll discuss your experience, provide a body fat analysis, and outline a
            personalized PT program and diet plan to ensure your $100 is well spent. This includes a roadmap for your
            fitness journey with us.
          </p>
        </div>
      </div>

      <div className="flex flex-col space-y-10 pb-20 md:pb-10">
        <h2 className="text-xl font-bold text-center font-abel">
          No prefills of forms. Say Hi! now to check availability. It's that fast and simple.
        </h2>

        <div className="text-center">
          <a
            href="https://api.whatsapp.com/send?phone=6592312967&text=Hi%20there!%20I%20would%20like%20to%20check%20the%20availability%20for%20a%20trial%20session.%20"
            className="bg-gray-400 hover:bg-gray-300 text-white font-bold py-2 px-4 rounded cursor-pointer font-abel"
            target="_blank"
            rel="noopener noreferrer"
          >
            START NOW
          </a>
        </div>
      </div>
    </div>
  );
};

export default TrialSection;
