import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import setAuthToken from "../services/authService";
import api from "../api";

const Login = ({ setIsAuthenticated, setRole, user, setUser }) => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  // const [loginSuccess, setLoginSuccess] = useState(false);
  const [loginError, setLoginError] = useState(false);

  // Destructures email and password from formData for easy access
  const { email, password } = formData;
  const navigate = useNavigate();

  // e.target.name: Refers to the name attribute of the input element being changed.
  // e.target.value: Refers to the value of the input element being changed.
  // setFormData: Updates the formData state with the new value while keeping the rest of the data intact.
  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  // axios.post: Sends a POST request to the /api/auth/login endpoint with formData.
  // localStorage.setItem('token', res.data.token): Stores the received token in localStorage.
  // history.push('/dashboard'): Redirects the trainer to the /dashboard route upon successful login.
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      // Debug statement to check the API base URL
      console.log("API Base URL:", api.defaults.baseURL);
      const res = await api.post("/api/login", formData);
      console.log("LOGGING IN...:", res);
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("user", JSON.stringify(res.data.user)); // Store user data in localStorage
      localStorage.setItem("role", res.data.user.role);
      // setLoginSuccess(true);

      setUser(res.data.user);
      console.log(res.data.user);
      setAuthToken(res.data.token);
      setIsAuthenticated(true);
      setRole(res.data.user.role);
      console.log(res.data.user.role);
      navigate(res.data.user.role === "admin" ? "/admindashboard" : "/trainerdashboard");
    } catch (err) {
      console.error(err.response.data);
      setLoginError(true);
    }
  };

  /* <form>: The form element that triggers onSubmit when submitted.
<input> (email): An input field for the email, with its value controlled by the email state. It triggers onChange on trainer input.
<input> (password): An input field for the password, with its value controlled by the password state. It also triggers onChange on trainer input.
<button>: A submit button that triggers the form submission. */

  return (
    <div className="flex justify-center min-h-screen bg-stone-200">
      <div className="p-6 rounded-lg w-full max-w-sm">
        <form onSubmit={onSubmit}>
          <div className="mb-4">
            <label className="block font-bold text-sm mb-2">EMAIL ADDRESS </label>

            <input
              type="email"
              name="email"
              value={email}
              onChange={onChange}
              placeholder="Email"
              required
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="mb-6">
            <label className="block font-bold text-sm mb-2">PASSWORD </label>

            <input
              type="password"
              name="password"
              value={password}
              onChange={onChange}
              placeholder="Password"
              required
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="mb-4 text-center">
            <Link to="/forgotpassword" className="text-sm text-blue-500 hover:underline">
              Forgot Password?
            </Link>
          </div>
          <div className="flex items-center justify-center">
            <button className="text-white bg-black font-medium text-sm px-5 py-2.5 rounded" name="login">
              LOGIN
            </button>
          </div>
        </form>

        <div className="mt-4 text-center">
          {/* {loginSuccess && <p className="text-green-400">Successfully Logged In!</p>} */}
          {loginError && <p className="text-red-400">Login Failed, Please Try Again!</p>}
        </div>
      </div>
    </div>
  );
};

export default Login;
