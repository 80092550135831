import React from "react";
import Modal from "react-modal";
const TrainingModal = ({
  setIsModalOpen,
  isModalOpen,
  setEditTraining,
  setFormData,
  formData,
  customers,
  user,
  handleSubmit,
  handleInputChange,
  editTraining,
  handleDelete,
  trainers,
}) => {
  return (
    <div>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
          setEditTraining(null);
          setFormData({ customerID: "", trainerID: "", date: "", status: "scheduled" });
        }}
        contentLabel="Schedule Training"
        className="fixed inset-0 flex items-center justify-center p-4 z-50"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
      >
        <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md relative">
          <button
            onClick={() => {
              setIsModalOpen(false);
              setEditTraining(null);
              setFormData({ customerID: "", trainerID: "", date: "", status: "scheduled" });
            }}
            className="absolute top-2 right-2 text-gray-900 hover:text-gray-900"
          >
            X
          </button>
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="mb-4">
              <label className="block font-semibold text-sm mb-2">Customer</label>
              <select
                name="customerID"
                value={formData.customerID}
                onChange={(e) => {
                  handleInputChange(e);

                  // Automatically set the trainerID based on the selected customer's trainer
                  const selectedCustomer = customers.find((c) => c._id === e.target.value);
                  if (selectedCustomer && user.role === "admin") {
                    setFormData((prev) => ({
                      ...prev,
                      trainerID: selectedCustomer.trainerID || "", // Set trainerID if available
                    }));
                  }
                }}
                required
                className="w-full p-2 border border-gray-300 rounded"
              >
                <option value="">Select Customer</option>
                {user.role === "trainer"
                  ? customers
                      .filter((customer) => customer.trainerID === user._id)
                      .map((customer) => (
                        <option key={customer._id} value={customer._id}>
                          {customer.name}
                        </option>
                      ))
                  : customers.map((customer) => (
                      <option key={customer._id} value={customer._id}>
                        Customer: {customer.name}, Trainer: {customer.trainerID?.name || "No Trainer"}
                      </option>
                    ))}
              </select>
            </div>
            {/* Only show the trainer selection if the user is an admin
            {user.role === "admin" && !editTraining && (
              <div className="mb-4">
                <label className="block font-semibold text-sm mb-2">Trainer</label>
                <select
                  name="trainerID"
                  value={formData.trainerID}
                  onChange={handleInputChange}
                  required
                  className="w-full p-2 border border-gray-300 rounded"
                >
                  <option value="">Select Trainer</option>
                  {trainers.map((trainer) => (
                    <option key={trainer._id} value={trainer._id}>
                      {trainer.name}
                    </option>
                  ))}
                </select>
              </div>
            )} */}
            <div className="mb-4">
              <label className="block font-semibold text-sm mb-2">Date</label>
              <input
                type="datetime-local"
                name="date"
                value={formData.date}
                onChange={handleInputChange}
                required
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
            <div className="flex justify-between">
              <button className="text-white bg-black font-medium text-sm px-5 py-2.5 rounded">
                {editTraining ? "Update Training" : "Schedule Training"}
              </button>
              {editTraining && (
                <button
                  type="button"
                  onClick={handleDelete}
                  className="text-white bg-red-500 font-medium text-sm px-5 py-2.5 rounded"
                >
                  Delete Training
                </button>
              )}
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default TrainingModal;
