import React from "react";
import founder1 from "../assets/FounderPage/founderpage1.png";
import founder2 from "../assets/FounderPage/founderpage2.png";
import mobileFounder1 from "../assets/FounderPage/mobileFounder1.jpeg";
import mobileFounder2 from "../assets/FounderPage/mobileFounder2.jpeg";
import medal1st from "../assets/FounderPage/medal1.png";
import medal2nd from "../assets/FounderPage/medal2.png";
import medal6th from "../assets/FounderPage/medal3.png";
import hypertrophyIcon from "../assets/FounderPage/hypertrophy.png";
import strengthIcon from "../assets/FounderPage/strength.png";
import muscleBuildingIcon from "../assets/FounderPage/muscleBuilding.png";
import weightLossIcon from "../assets/FounderPage/weightLoss.png";
import fitnessIcon from "../assets/FounderPage/fitness.png";
import personalTrainerIcon from "../assets/FounderPage/personalTrainer.png";
import fatLossCoachIcon from "../assets/FounderPage/fatLossCoach.png";
import rehabTrainerIcon from "../assets/FounderPage/rehabTrainer.png";
import trxTrainerIcon from "../assets/FounderPage/trxTrainer.png";
import cprAedIcon from "../assets/FounderPage/cprAed.png";

const Founder = () => {
  return (
    <div className="bg-stone-200 min-h-screen w-screen">
      <div className="mx-auto p-4 md:p-0">
        {/* Card 1 */}
        <div className="relative h-auto md:h-[30rem] w-full rounded mb-8">
          <img src={founder1} alt="Founder" className="w-full h-full object-cover rounded hidden md:block" />
          <img src={mobileFounder1} alt="Founder" className="w-full h-full object-cover rounded md:hidden" />
          <div className="md:absolute inset-0 flex items-center justify-center md:justify-end pr-4 md:pr-[6rem] mt-4 md:mt-0">
            <div className="md:bg-white md:bg-opacity-70 md:rounded-3xl w-full md:w-[50rem] mx-4 md:mx-0 md:py-4 md:px-6">
              <h2 className="text-2xl md:text-3xl font-bold mt-4 mt-md:1 mb-2 text-center">FOUNDING PHILOSOPHY</h2>
              <p className="text-base md:text-lg mb-6 font-abel text-justify md:px-6">
                "Believe exercise is the foundation of youth and should be part of your daily life. Remember life is a
                journey and fitness will help that journey not only physically but mentally as well. I care about my
                clients, and there’s nothing more important to me than helping somebody go through an experience that
                makes them happy, confident, and strong. I'm here to guide you every step of the way."
              </p>

              <div className="w-full md:w-auto md:bg-transparent md:p-0 mx-4 md:mx-0">
                <h2 className="text-2xl md:text-3xl font-bold mt-12 mb-2 text-center">SPECIALIZATIONS</h2>
                <div className="ml-[-2rem] md:ml-0 mt-4 grid grid-cols-2 md:grid-cols-3 gap-6 md:gap-4 md:gap-x-8 px-4 md:px-0">
                  <div className="flex items-center font-sans text-base md:text-lg uppercase">
                    <img src={hypertrophyIcon} alt="Hypertrophy" className="w-8 h-8 mr-2" />
                    HYPERTROPHY
                  </div>
                  <div className="flex items-center font-sans text-base md:text-lg uppercase">
                    <img src={strengthIcon} alt="Strength & Conditioning" className="w-8 h-8 mr-2" />
                    STRENGTH & CONDITIONING
                  </div>
                  <div className="flex items-center font-sans text-base md:text-lg uppercase">
                    <img src={muscleBuildingIcon} alt="Muscle Building" className="w-8 h-8 mr-2" />
                    MUSCLE BUILDING
                  </div>
                  <div className="flex items-center font-sans text-base md:text-lg uppercase">
                    <img src={weightLossIcon} alt="Weight Loss" className="w-8 h-8 mr-2" />
                    WEIGHT LOSS
                  </div>
                  <div className="flex items-center font-sans text-base md:text-lg uppercase">
                    <img src={fitnessIcon} alt="General Fitness" className="w-8 h-8 mr-2" />
                    GENERAL FITNESS
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Card 2 */}
        <div className="relative h-auto md:h-[35rem] w-full md:w-[60rem] md:ml-[18rem] mb-8 md:mt-[-2rem]">
          <div className="flex items-center justify-center md:justify-center">
            <div className="w-full">
              <h2 className="text-2xl md:text-3xl font-bold mt-6 md:mt-[6rem] md:mb-12 text-center md:text-left md:ml-[23rem]">
                QUALIFICATIONS
              </h2>
              <div className="mt-4 grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-x-8">
                <div className="flex flex-col items-center">
                  <img
                    src={personalTrainerIcon}
                    alt="Personal Trainer"
                    className="w-[4rem] h-[4rem] md:w-[5rem] md:h-[5rem] mb-2"
                  />
                  <p className="text-center text-sm md:text-lg font-sans">FIT SINGAPORE PERSONAL TRAINER CERTIFIED</p>
                </div>
                <div className="flex flex-col items-center">
                  <img
                    src={fatLossCoachIcon}
                    alt="Fat Loss Coach"
                    className="w-[4rem] h-[4rem] md:w-[5rem] md:h-[5rem] mb-2"
                  />
                  <p className="text-center text-sm md:text-lg font-sans">CERTIFIED FAT LOSS COACH</p>
                </div>
                <div className="flex flex-col items-center">
                  <img
                    src={rehabTrainerIcon}
                    alt="Rehab Trainer"
                    className="w-[4rem] h-[4rem] md:w-[5rem] md:h-[5rem] mb-2"
                  />
                  <p className="text-center text-sm md:text-lg font-sans">REHAB TRAINER ESSENTIAL CERTIFIED</p>
                </div>
                <div className="flex flex-col items-center md:mt-4 mt-[-0.1rem]">
                  <img
                    src={trxTrainerIcon}
                    alt="TRX Trainer"
                    className="w-[4rem] h-[4rem] md:w-[5rem] md:h-[5rem] mb-2"
                  />
                  <p className="text-center text-sm md:text-lg font-sans">CERTIFIED TRX FUNCTIONAL TRAINER</p>
                </div>
                <div className="flex flex-col items-center md:mt-4 md:mb-0 mb-8">
                  <img src={cprAedIcon} alt="CPR and AED" className="w-[4rem] h-[4rem] md:w-[5rem] md:h-[5rem] mb-2" />
                  <p className="text-center text-sm md:text-lg font-sans">CPR AND AED CERTIFIED</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Card 3 */}
        <div className="relative h-auto md:h-[40rem] w-full rounded mb-8">
          <img src={founder2} alt="Founder" className="w-full h-full object-cover rounded hidden md:block" />
          <img src={mobileFounder2} alt="Founder" className="w-full h-full object-cover rounded md:hidden" />
          <div className="md:absolute inset-0 flex items-center justify-center md:justify-end pr-4 md:pr-[6rem] mt-4 md:mt-0">
            <div className="md:bg-white md:bg-opacity-70 md:p-8 rounded-3xl w-full md:w-[50rem] mx-4 md:mx-0">
              <h2 className="text-2xl md:text-3xl font-bold mt-4 md:mt-1 mb-2 md:ml-0 ml-4 text-center">
                ACHIEVEMENTS
              </h2>
              <div className="mt-4 grid grid-cols-2 md:grid-cols-3 gap-4 md:gap-x-8 ml-4 md:ml-0">
                <div className="flex flex-col items-center">
                  <img
                    src={medal1st}
                    alt="1st Place Medal"
                    className="w-[4rem] h-[4rem] md:w-[5rem] md:h-[5rem] mb-2"
                  />
                  <p className="text-center text-sm md:text-lg font-sans">
                    1ST PLACE - FITNESS IRONMAN BODYBUILDING BELOW 70KG
                  </p>
                </div>
                <div className="flex flex-col items-center">
                  <img
                    src={medal2nd}
                    alt="2nd Place Medal"
                    className="w-[4rem] h-[4rem] md:w-[5rem] md:h-[5rem] mb-2"
                  />
                  <p className="text-center text-sm md:text-lg font-sans">
                    2ND PLACE - SINGAPORE NATIONALS BODYBUILDING UNDER 70KG
                  </p>
                </div>
                <div className="flex flex-col items-center">
                  <img
                    src={medal1st}
                    alt="1st Place Medal"
                    className="w-[4rem] h-[4rem] md:w-[5rem] md:h-[5rem] mb-2"
                  />
                  <p className="text-center text-sm md:text-lg font-sans">
                    1ST PLACE - WBPF CLASSIC BODYBUILDING UNDER 170CM
                  </p>
                </div>
                <div className="flex flex-col items-center md:mt-4 mt-[-0.1rem]">
                  <img
                    src={medal2nd}
                    alt="2nd Place Medal"
                    className="w-[4rem] h-[4rem] md:w-[5rem] md:h-[5rem] mb-2"
                  />
                  <p className="text-center text-sm md:text-lg font-sans">
                    2ND PLACE - SFBF CLASSIC BODYBUILDING BELOW 170CM
                  </p>
                </div>
                <div className="flex flex-col items-center mt-4">
                  <img
                    src={medal6th}
                    alt="6th Place Medal"
                    className="w-[4rem] h-[4rem] md:w-[5rem] md:h-[5rem] mb-2"
                  />
                  <p className="text-center text-sm md:text-lg font-sans">
                    6TH PLACE - SINGAPORE BODYBUILDING OPEN CATEGORY
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h2 className="text-lg md:text-xl font-bold text-center mb-8 mt-12 font-abel">
          No prefills of forms. Say Hi! now to check availability. It's that fast and simple.
        </h2>

        <div className="text-center pb-28">
          <a
            href="https://api.whatsapp.com/send?phone=6592312967&text=Hi%20there!%20I%20would%20like%20to%20check%20the%20availability%20for%20a%20trial%20session.%20"
            className="bg-gray-400 hover:bg-gray-300 text-white font-bold py-2 px-4 rounded cursor-pointer font-abel text-sm md:text-base"
            target="_blank"
            rel="noopener noreferrer"
          >
            START NOW
          </a>
        </div>
      </div>
    </div>
  );
};

export default Founder;
