import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import NavBar from "./components/NavBar";
import Homepage from "./components/Homepage";
import Founder from "./components/Founder";
import BodyTransformation from "./components/BodyTransformation";
import WeightLoss from "./components/WeightLoss";
import Footer from "./components/Footer";
import Login from "./components/Login.js";
import Register from "./components/Register.js";
import AdminDashboard from "./components/AdminDashboard.js";
import TrainerDashboard from "./components/TrainerDashboard";
import Trainings from "./components/Trainings";
import Trainers from "./components/Trainers";
import Payments from "./components/Payments";
import Profile from "./components/Profile";
import ForgotPassword from "./components/ForgotPassword";
import Customers from "./components/Customers";
import setAuthToken from "./services/authService";
import ResetPassword from "./components/ResetPassword";
import api from "./api";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState(null);
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    // Clear the token and role from localStorage on initial load
    // localStorage.removeItem("token");
    // localStorage.removeItem("role");

    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
    const storedUser = localStorage.getItem("user");
    if (token && storedUser) {
      setAuthToken(token);
      setIsAuthenticated(true);
      setRole(role);
      setUser(JSON.parse(storedUser)); // Restore user data from localStorage
    } else {
      setIsAuthenticated(false);
      setRole(null);
    }
  }, []);

  // const handleLogout = async () => {
  //   try {
  //     const token = localStorage.getItem("token");
  //     if (token) {
  //       await api.post("/api/logout");
  //     }

  //     localStorage.removeItem("token");
  //     localStorage.removeItem("role");
  //     localStorage.removeItem("name");
  //     setAuthToken(null);
  //     setIsAuthenticated(false);
  //     setRole(null); // Redirect to login page after logout
  //     navigate("/login");
  //   } catch (error) {
  //     console.error("Error logging out:", error);
  //   }
  // };

  const handleLogout = async () => {
    try {
      // Attempt to log out via the backend API
      await api.post("/api/logout", {}, { withCredentials: true });
    } catch (error) {
      console.error("Error logging out:", error);
      // Check if the error is related to the user not being found and handle accordingly
      if (error.response && error.response.status === 404) {
        console.warn("User not found. Clearing local session.");
      }
    } finally {
      // Regardless of the outcome, remove the token and redirect the user
      localStorage.removeItem("token"); // Clear any stored token
      window.location.href = "/login"; // Redirect to login page
    }
  };

  return (
    <div className="font-sans tracking-[.12em] overflow-x-hidden">
      {" "}
      <div>
        <NavBar isAuthenticated={isAuthenticated} handleLogout={handleLogout} role={role} user={user} />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/founder" element={<Founder />} />
          <Route path="/bodytransformation" element={<BodyTransformation />} />
          <Route path="/weightloss" element={<WeightLoss />} />
          <Route
            path="/login"
            element={<Login setIsAuthenticated={setIsAuthenticated} setRole={setRole} user={user} setUser={setUser} />}
          />
          <Route
            path="/register"
            element={<Register setIsAuthenticated={setIsAuthenticated} setRole={setRole} user={user} />}
          />{" "}
          {isAuthenticated && role === "admin" && (
            <Route path="/admindashboard" element={<AdminDashboard user={user} />} />
          )}
          {isAuthenticated && role === "admin" && (
            <Route path="/trainers" element={<Trainers isAuthenticated={isAuthenticated} />} />
          )}
          {isAuthenticated && role === "trainer" && <Route path="/trainerdashboard" element={<TrainerDashboard />} />}
          {isAuthenticated && (role === "admin" || role === "trainer") && (
            <Route path="/trainings" element={<Trainings user={user} role={role} />} />
          )}
          {isAuthenticated && (role === "admin" || role === "trainer") && (
            <Route path="/payments" element={<Payments isAuthenticated={isAuthenticated} role={role} />} />
          )}
          {isAuthenticated && (role === "admin" || role === "trainer") && (
            <Route path="/customers" element={<Customers isAuthenticated={isAuthenticated} role={role} />} />
          )}
          {isAuthenticated && (role === "admin" || role === "trainer") && (
            <Route path="/profile" element={<Profile isAuthenticated={isAuthenticated} role={role} />} />
          )}
          <Route path="/forgotpassword" element={<ForgotPassword />} />{" "}
          <Route path="/reset-password/:token" element={<ResetPassword />} />
        </Routes>{" "}
        <Footer />
      </div>{" "}
    </div>
  );
}

export default App;
