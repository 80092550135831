import React from "react";
import CTAbutton from "./CTAbutton";
import subhero1 from "../assets/Homepage/subhero1.png";
import subhero2 from "../assets/Homepage/subhero2.png";

const SubHeroSection = () => {
  return (
    <div className="pt-20 md:pt-16 h-screen w-screen flex flex-col items-center justify-center bg-stone-200">
      <div className="flex flex-col md:flex-row w-full">
        {/* Image 1 */}
        <div className="relative w-full md:w-1/2 h-[50vh] md:h-[70vh] overflow-hidden shadow-2xl">
          <img src={subhero1} alt="Image 1" className="w-full h-full object-cover" />
          <div className="absolute inset-0 flex flex-col items-center justify-center p-8 text-white">
            {" "}
            <p className="text-base md:text-lg mb-4 font-abel">Transform Your Body in 30 Days With Our</p>
            <h2 className="text-lg md:text-2xl font-bold pb-8 text-center">BODY TRANSFORMATION PROGRAM</h2>
            <CTAbutton page="bodytransformation" title="Learn More" />
          </div>
        </div>

        {/* Image 2 */}
        <div className=" relative w-full md:w-1/2 h-[50vh] md:h-[70vh] overflow-hidden shadow-2xl">
          <img src={subhero2} alt="Image 2" className="w-full h-full object-cover" />
          <div className="absolute inset-0 flex flex-col items-center justify-center p-8 text-white">
            <p className="text-base md:text-lg mb-4 font-abel">Lose Weight in Just 2 Weeks With Our</p>
            <h2 className="text-lg md:text-2xl font-bold pb-8">WEIGHT LOSS PROGRAM</h2>

            <CTAbutton page="weightloss" title="Learn More" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubHeroSection;
