import React, { useEffect, useState } from "react";
import AdminTrainingChart from "./AdminTrainingChart";
import { IoCloseCircle, IoAddCircle, IoCheckmarkCircle } from "react-icons/io5";
import api from "../api";

const AdminDashboard = ({ user }) => {
  const [users, setUsers] = useState([]);
  const [trainings, setTrainings] = useState([]);
  const [filteredTrainings, setFilteredTrainings] = useState([]);
  const [selectedTrainer, setSelectedTrainer] = useState("");
  const now = new Date();
  const past90Days = new Date(now);
  past90Days.setDate(past90Days.getDate() - 90);

  // FETCH USERS, TRAINERS
  const fetchUsers = async () => {
    try {
      const res = await api.get("/api/users");
      setUsers(res.data);
    } catch (error) {
      console.error("Error fetching users", error);
    }
  };

  // FETCH TRAININGS
  const fetchTrainings = async () => {
    try {
      const res = await api.get("/api/training");
      setTrainings(res.data); // Save all trainings
      setFilteredTrainings(res.data); // Display all trainings on load
    } catch (error) {
      console.error("Error fetching trainings", error);
    }
  };

  // CALL FUNCTIONS UPON LOADING OF PAGE
  useEffect(() => {
    if (user) {
      fetchUsers();
      fetchTrainings();
    }
  }, [user]);

  // FILTER BY TRAINER
  useEffect(() => {
    if (selectedTrainer) {
      const filtered = trainings.filter((training) => training.trainerID.name === selectedTrainer);
      setFilteredTrainings(filtered);
    } else {
      setFilteredTrainings(trainings);
    }
  }, [selectedTrainer, trainings]);

  // CALCULATE STATS FOR TRAININGS (ALL/FILTERED)
  const calculateTrainingStats = (trainings) => {
    let scheduledCounter = 0;
    let completedCounter = 0;
    const next90Days = new Date(now);
    next90Days.setDate(next90Days.getDate() + 90);
    const pastSessionsLast90Days = trainings.filter((training) => {
      const trainingDate = new Date(training.dateOfTraining);
      const isWithinPast90Days = trainingDate >= past90Days && trainingDate < now;
      if (isWithinPast90Days) {
        if (training.status === "scheduled") {
          scheduledCounter++;
        } else if (training.status === "completed") {
          completedCounter++;
        }
      }
      return isWithinPast90Days;
    });
    const upcomingSessionsNext90Days = trainings.filter(
      (training) => new Date(training.dateOfTraining) >= now && new Date(training.dateOfTraining) <= next90Days
    );
    const avgPastSessionsPerWeek = pastSessionsLast90Days.length / 8.57; // 90 days / 7 days per week = 8.57 weeks

    return {
      pastCount: pastSessionsLast90Days.length,
      upcomingCount: upcomingSessionsNext90Days.length,
      avgPastSessionsPerWeek: avgPastSessionsPerWeek.toFixed(2),
      scheduledCount: scheduledCounter,
      completedCount: completedCounter,
    };
  };

  // GROUP TRAININGS BY TRAINER
  const groupTrainingsByTrainer = () => {
    return filteredTrainings.reduce((acc, training) => {
      const trainerName = training.trainerID.name;
      if (!acc[trainerName]) {
        acc[trainerName] = [];
      }
      acc[trainerName].push(training);
      return acc;
    }, {});
  };

  // GET TRAINING GROUPED BY TRAINER
  const groupedTrainings = groupTrainingsByTrainer();

  // SORT DESCENDING (LATEST TO OLDEST)
  const sortTrainingsByDateDesc = (trainings) => {
    return trainings.sort((a, b) => new Date(b.dateOfTraining) - new Date(a.dateOfTraining));
  };

  // SORT ASCENDING (NEAREST DATE TO FURTHEST OUT)
  const sortTrainingsByDateAsc = (trainings) => {
    return trainings.sort((a, b) => new Date(a.dateOfTraining) - new Date(b.dateOfTraining));
  };

  return (
    <div className="w-full pb-24 pl-8 pr-8 bg-stone-200">
      <div className="h- md:h-12"></div>
      {/* FILTER: BY TRAINER */}
      <div className="md:flex-row flex flex-col">
        <label className="block font-bold text-lg md:text-xl  pt-1">Filter by Trainer </label>

        <div className="pt-4 md:pt-0 mg:pt-0 md:w-1/4 md:pl-10">
          <select
            value={selectedTrainer}
            onChange={(e) => setSelectedTrainer(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
          >
            <option value="">All Trainers</option>
            {users
              .filter((trainer) => trainer.role === "trainer")
              .map((trainer) => (
                <option key={trainer._id} value={trainer.name}>
                  {trainer?.name}
                </option>
              ))}
          </select>
        </div>
      </div>
      <div className="h-4"></div>

      {/* CHART: TRAINING STATS */}
      <AdminTrainingChart filteredTrainings={filteredTrainings} selectedTrainer={selectedTrainer} />
      {/* TABLE: TRAINING STATS */}
      <div className="pb-8">
        <h3 className="text-lg md:text-xl font-semibold mb-4 text-gray-800">Training Stats</h3>
        <div className="overflow-x-auto shadow-lg rounded-lg">
          {/* Table for larger screens */}
          <table className="min-w-full bg-white rounded-lg overflow-hidden hidden md:table">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-6 py-4 text-left text-sm font-medium text-gray-600">Trainer</th>
                <th className="px-6 py-4 text-left text-sm font-medium text-gray-600">Past Trainings (Last 90 Days)</th>
                <th className="px-6 py-4 text-left text-sm font-medium text-gray-600">Avg. Past Trainings/90 Days</th>
                <th className="px-6 py-4 text-left text-sm font-medium text-gray-600">
                  Upcoming Trainings (Next 90 Days)
                </th>
              </tr>
            </thead>
            <tbody>
              {/* Loop through grouped trainings */}
              {Object.keys(groupedTrainings).map((trainerName) => {
                const trainingsByTrainer = groupedTrainings[trainerName];
                const stats = calculateTrainingStats(trainingsByTrainer);

                return (
                  <tr key={trainerName} className="hover:bg-gray-50">
                    <td className="px-6 py-4 border-t border-gray-200">
                      <span className="text-lg font-semibold text-gray-800">{trainerName}</span>
                    </td>
                    <td className="px-6 py-4 border-t border-gray-200">
                      <div className="flex justify-between items-center">
                        <span className="bg-blue-100 text-blue-600 text-sm px-2 py-1 rounded-full">
                          {stats.pastCount} Total
                        </span>
                      </div>
                      <div className="flex items-center text-sm text-gray-600 mt-2">
                        <IoCloseCircle className="mr-2" /> Scheduled: {stats.scheduledCount}
                      </div>
                      <details className="bg-gray-50 rounded-lg p-2 mt-2">
                        <summary className="cursor-pointer text-sm text-gray-600">View Scheduled Trainings</summary>
                        <ul className="pl-6 list-disc list-inside text-gray-500 mt-2">
                          {sortTrainingsByDateDesc(
                            trainingsByTrainer.filter(
                              (training) =>
                                new Date(training.dateOfTraining) >= past90Days &&
                                new Date(training.dateOfTraining) < now &&
                                training.status === "scheduled"
                            )
                          ).map((training) => (
                            <li key={training._id} className="text-sm">
                              {new Date(training.dateOfTraining).toLocaleString([], {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                            </li>
                          ))}
                        </ul>
                      </details>
                      <div className="flex items-center text-sm text-gray-600 mt-2">
                        <IoCheckmarkCircle className="mr-2" /> Completed: {stats.completedCount}
                      </div>
                      <details className="bg-gray-50 rounded-lg p-2 mt-2">
                        <summary className="cursor-pointer text-sm text-gray-600">View Completed Trainings</summary>
                        <ul className="pl-6 list-disc list-inside text-gray-500 mt-2">
                          {sortTrainingsByDateDesc(
                            trainingsByTrainer.filter(
                              (training) =>
                                new Date(training.dateOfTraining) >= past90Days &&
                                new Date(training.dateOfTraining) < now &&
                                training.status === "completed"
                            )
                          ).map((training) => (
                            <li key={training._id} className="text-sm">
                              {new Date(training.dateOfTraining).toLocaleString([], {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                            </li>
                          ))}
                        </ul>
                      </details>
                    </td>
                    <td className="px-6 py-4 border-t border-gray-200 text-gray-700">
                      <span className="text-lg font-medium">{stats.avgPastSessionsPerWeek}</span>
                    </td>
                    <td className="px-6 py-4 border-t border-gray-200">
                      <div className="flex justify-between items-center">
                        <span className="bg-green-100 text-green-600 text-sm px-2 py-1 rounded-full">
                          {stats.upcomingCount} Total
                        </span>
                      </div>
                      <details className="bg-gray-50 rounded-lg p-2 mt-2">
                        <summary className="cursor-pointer text-sm text-gray-600">View Upcoming Trainings</summary>
                        <ul className="pl-6 list-disc list-inside text-gray-500 mt-2">
                          {sortTrainingsByDateAsc(
                            trainingsByTrainer.filter(
                              (training) =>
                                new Date(training.dateOfTraining) >= now &&
                                new Date(training.dateOfTraining) <= new Date(now.getTime() + 90 * 24 * 60 * 60 * 1000)
                            )
                          ).map((training) => (
                            <div key={training._id} className="flex items-center mb-2">
                              <IoAddCircle className="text-black text-base mr-2" />
                              <span>
                                {new Date(training.dateOfTraining).toLocaleString([], {
                                  year: "numeric",
                                  month: "short",
                                  day: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                })}
                              </span>
                            </div>
                          ))}
                        </ul>
                      </details>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {/* Card layout for mobile */}
          <div className="md:hidden space-y-4">
            {Object.keys(groupedTrainings).map((trainerName) => {
              const trainingsByTrainer = groupedTrainings[trainerName];
              const stats = calculateTrainingStats(trainingsByTrainer);

              return (
                <div key={trainerName} className="bg-white shadow rounded-lg p-4">
                  <h4 className="text-lg font-semibold text-gray-800 mb-2">{trainerName}</h4>

                  <div className="text-gray-700 space-y-2">
                    <div className="flex items-center justify-between">
                      <p className="font-medium">Past Trainings:</p>
                      <span className="bg-blue-100 text-blue-600 text-sm px-2 py-1 rounded-full">
                        {stats.pastCount} Total
                      </span>
                    </div>

                    <div className="flex items-center text-sm text-gray-600">
                      <IoCloseCircle className="mr-2" />
                      Scheduled: {stats.scheduledCount}
                    </div>

                    <details className="bg-gray-50 rounded-lg p-2">
                      <summary className="cursor-pointer text-sm text-gray-600">View Scheduled Trainings</summary>
                      <ul className="pl-6 list-disc list-inside text-gray-500 mt-2">
                        {sortTrainingsByDateDesc(
                          trainingsByTrainer.filter(
                            (training) =>
                              new Date(training.dateOfTraining) >= past90Days &&
                              new Date(training.dateOfTraining) < now &&
                              training.status === "scheduled"
                          )
                        ).map((training) => (
                          <li key={training._id} className="text-sm">
                            {new Date(training.dateOfTraining).toLocaleString([], {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                          </li>
                        ))}
                      </ul>
                    </details>

                    <div className="flex items-center text-sm text-gray-600 mt-2">
                      <IoCheckmarkCircle className="mr-2" />
                      Completed: {stats.completedCount}
                    </div>

                    <details className="bg-gray-50 rounded-lg p-2">
                      <summary className="cursor-pointer text-sm text-gray-600">View Completed Trainings</summary>
                      <ul className="pl-6 list-disc list-inside text-gray-500 mt-2">
                        {sortTrainingsByDateDesc(
                          trainingsByTrainer.filter(
                            (training) =>
                              new Date(training.dateOfTraining) >= past90Days &&
                              new Date(training.dateOfTraining) < now &&
                              training.status === "completed"
                          )
                        ).map((training) => (
                          <li key={training._id} className="text-sm">
                            {new Date(training.dateOfTraining).toLocaleString([], {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            })}
                          </li>
                        ))}
                      </ul>
                    </details>

                    <div className="mt-4">
                      <p className="font-medium">Avg. Past Trainings/90 Days:</p>
                      <span className="text-lg text-gray-800">{stats.avgPastSessionsPerWeek}</span>
                    </div>

                    <div className="flex items-center justify-between mt-4">
                      <p className="font-medium">Upcoming Trainings:</p>
                      <span className="bg-green-100 text-green-600 text-sm px-2 py-1 rounded-full">
                        {stats.upcomingCount} Total
                      </span>
                    </div>

                    <details className="bg-gray-50 rounded-lg p-2">
                      <summary className="cursor-pointer text-sm text-gray-600">View Upcoming Trainings</summary>
                      <ul className="pl-6 list-disc list-inside text-gray-500 mt-2">
                        {sortTrainingsByDateAsc(
                          trainingsByTrainer.filter(
                            (training) =>
                              new Date(training.dateOfTraining) >= now &&
                              new Date(training.dateOfTraining) <= new Date(now.getTime() + 90 * 24 * 60 * 60 * 1000)
                          )
                        ).map((training) => (
                          <div key={training._id} className="flex items-center mb-2">
                            <IoAddCircle className="text-black text-base mr-2" />
                            <span>
                              {new Date(training.dateOfTraining).toLocaleString([], {
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                            </span>
                          </div>
                        ))}
                      </ul>
                    </details>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
