import axios from "axios";

// Create the Axios instance with a base URL
const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL || "http://localhost:4000", // Adjust the base URL accordingly.
  withCredentials: true,
});

// Add a request interceptor to include the token in the headers
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token"); // Adjust based on where you store the token.
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

async function handleLogout() {
  try {
    await api.post("/api/logout", {}, { withCredentials: true });
    localStorage.removeItem("token"); // Clear any stored token
    window.location.href = "/login"; // Redirect to login page
  } catch (error) {
    console.error("Error logging out:", error);
  }
}

export default api;
