import React from "react";
import { IoCloseCircle, IoAddCircle, IoCheckmarkCircle } from "react-icons/io5";

const TrainingStats = ({
  filteredTrainings,
  selectedCustomer,
  claimTraining,
  deleteTraining,
  formatDate,
  setEditTraining,
  setFormData,
  setIsModalOpen,
  now,
  past90Days,
  trainingStats,
}) => {
  // SORT DESCENDING (LATEST TO OLDEST)
  const sortTrainingsByDateDesc = (trainings) => {
    return trainings.sort((a, b) => new Date(b.dateOfTraining) - new Date(a.dateOfTraining));
  };

  // SORT ASCENDING (NEAREST DATE TO FURTHEST OUT)
  const sortTrainingsByDateAsc = (trainings) => {
    return trainings.sort((a, b) => new Date(a.dateOfTraining) - new Date(b.dateOfTraining));
  };

  return (
    <div>
      {/* TABLE: TRAINING STATS */}
      {selectedCustomer && trainingStats && (
        <div className="w-full pb-8">
          <h3 className="text-lg md:text-xl font-semibold pb-2">Training Stats of Selected Customer</h3>
          <div className="w-[100%] flex flex-col md:flex-row">
            <div className="w-full md:w-1/3 mb-4 md:mb-0 md:pr-2">
              <div className="bg-white shadow-md rounded-lg p-4">
                <h4 className="text-sm md:text-lg text-gray-700 font-semibold mb-2">Past Trainings (Last 90 Days)</h4>
                <p className="text-sm md:text-base mb-2">TOTAL: {trainingStats.pastCount}</p>
                <div className="flex items-center text-sm md:text-base">
                  <IoCloseCircle className="text-sm md:text-base text-red-500 mr-2" />
                  Scheduled/unclaimed: {trainingStats.scheduledCount}
                </div>
                {/* List for past scheduled */}
                <ul className="pl-4 list-disc list-inside text-xs md:text-sm mb-4">
                  {sortTrainingsByDateDesc(
                    filteredTrainings.filter(
                      (training) =>
                        new Date(training.dateOfTraining) >= past90Days &&
                        new Date(training.dateOfTraining) < now &&
                        training.status === "scheduled"
                    )
                  ).map((training) => (
                    <li key={training._id} className="mb-2">
                      <button
                        className="bg-green-400 p-1 rounded-xl text-xs md:text-sm text-white hover:scale-105 transition-transform duration-300 pl-2 pr-2"
                        onClick={() => claimTraining(training._id)}
                      >
                        Claim
                      </button>
                      <button
                        className="bg-indigo-400 p-1 rounded-xl text-xs md:text-sm text-white hover:scale-105 transition-transform duration-300 m-2 pl-2 pr-2"
                        onClick={() => deleteTraining(training._id)}
                      >
                        Delete
                      </button>
                      {new Date(training.dateOfTraining).toLocaleString([], {
                        year: "2-digit",
                        month: "short",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </li>
                  ))}
                </ul>
                <div className="flex items-center text-sm md:text-base">
                  <IoCheckmarkCircle className="text-sm md:text-base text-green-500 mr-2" />
                  Completed: {trainingStats.completedCount}
                </div>
                {/* List for past completed */}
                <ul className="pl-4 list-disc list-inside text-xs md:text-sm">
                  {sortTrainingsByDateDesc(
                    filteredTrainings.filter(
                      (training) =>
                        new Date(training.dateOfTraining) >= past90Days &&
                        new Date(training.dateOfTraining) < now &&
                        training.status === "completed"
                    )
                  ).map((training) => (
                    <li key={training._id} className="mb-2">
                      {new Date(training.dateOfTraining).toLocaleString([], {
                        year: "2-digit",
                        month: "short",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      })}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="w-full md:w-1/3 mb-4 md:mb-0 md:pr-2">
              <div className="bg-white shadow-md rounded-lg p-4">
                <h4 className="text-sm md:text-lg text-gray-700 font-semibold mb-2">Avg. Past Trainings/90 Days</h4>
                <p className="text-sm md:text-base">{trainingStats.avgPastSessionsPerWeek}</p>
              </div>
            </div>
            <div className="w-full md:w-1/3 md:pr-2">
              <div className="bg-white shadow-md rounded-lg p-4">
                <h4 className="text-sm md:text-lg text-gray-700 font-semibold mb-2">
                  Upcoming Trainings (Next 90 Days)
                </h4>
                <p className="text-sm md:text-base mb-2">TOTAL: {trainingStats.upcomingCount}</p>
                {/* List for upcoming trainings */}
                <ul className="list-disc list-inside text-xs md:text-sm">
                  {sortTrainingsByDateAsc(
                    filteredTrainings.filter(
                      (training) =>
                        new Date(training.dateOfTraining) >= now &&
                        new Date(training.dateOfTraining) <= new Date(now.getTime() + 90 * 24 * 60 * 60 * 1000)
                    )
                  ).map((training) => (
                    <div key={training._id} className="flex items-center mb-2">
                      <IoAddCircle className="text-black text-sm md:text-base mr-2" />
                      <span>
                        <button
                          className="bg-yellow-400 p-1 rounded-xl text-xs md:text-sm text-white hover:scale-105 transition-transform duration-300 pl-2 pr-2"
                          onClick={() => {
                            setEditTraining(training);
                            setFormData({
                              customerID: training.customerID._id,
                              date: formatDate(training.dateOfTraining),
                              status: training.status,
                            });
                            setIsModalOpen(true);
                          }}
                        >
                          Edit
                        </button>
                        <button
                          className="bg-indigo-400 p-1 rounded-xl text-xs md:text-sm text-white hover:scale-105 transition-transform duration-300 m-2 pl-2 pr-2"
                          onClick={() => deleteTraining(training._id)}
                        >
                          Delete
                        </button>
                      </span>
                      <span className="ml-2 text-xs md:text-sm">
                        {new Date(training.dateOfTraining).toLocaleString([], {
                          year: "2-digit",
                          month: "short",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </span>
                    </div>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TrainingStats;
