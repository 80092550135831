import React, { useEffect, useState } from "react";
import api from "../api";
import TrainerForm from "./TrainerForm";

const Trainers = ({ isAuthenticated }) => {
  const [users, setUsers] = useState([]);
  const [notification, setNotification] = useState("");
  const [formData, setFormData] = useState({ name: "", email: "", password: "", role: "" });
  const [inlineFormData, setInlineFormData] = useState({ name: "", email: "", role: "" });
  const [trainerForm, setTrainerForm] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [operationType, setOperationType] = useState(null);
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState("asc");

  // FETCH USERS
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await api.get("/api/users");
        console.log(res);
        setUsers(res.data);
      } catch (err) {
        console.error(err.response.data);
      }
    };
    fetchData();
  }, [isAuthenticated]);

  // USE EFFECT TO AUTO-CLOSE NOTIFICATION
  useEffect(() => {
    if (notification) {
      const timeout = setTimeout(() => {
        setNotification("");
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [notification]);

  // EDIT A USER
  const startEditing = (user) => {
    setEditUser(user);
    setInlineFormData(user);
    setOperationType(null);
  };

  const onInlineChange = (e) => setInlineFormData({ ...inlineFormData, [e.target.name]: e.target.value });

  // HANDLE CHANGE TO FORM DATA
  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  // ADD OR EDIT A USER
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      let res;
      if (editUser) {
        res = await api.put(`/api/users/${editUser._id}`, inlineFormData);
        // below updates the 'users' state to the new array generated by 'map'
        setUsers(users.map((user) => (user._id === editUser._id ? res.data : user))); // if true, returns res.data, else returns user object
        setNotification("Successfully Updated!");
        setOperationType("edit");
      } else {
        res = await api.post("/api/admin/users", formData);
        setUsers([...users, res.data]);
        setNotification("Successfully Added!");
        setOperationType("add");
        setTrainerForm(false);
      }
      setFormData({ name: "", email: "", password: "", role: "" });
      setInlineFormData({ name: "", email: "", role: "" });
      setEditUser(null);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } catch (err) {
      console.error(err.response.data);
      setNotification("Failed to Add/Update, Please Try Again.");
    }
  };

  // DELETE A USER
  const deleteUser = async (userId) => {
    try {
      await api.delete(`/api/users/${userId}`);
      setUsers((prevUsers) => prevUsers.filter((user) => user._id !== userId));
      setNotification("Successfully Deleted!");
    } catch (err) {
      console.error(err.response.data);
      setNotification("Failed to Delete, Please Try Again.");
    }
  };

  // SORTING
  const handleSort = (field) => {
    const sortedUsers = [...users].sort((a, b) => {
      let valueA, valueB;
      // Handle specific fields for sorting
      if (field === "dateCreated") {
        valueA = new Date(a.dateCreated);
        valueB = new Date(b.dateCreated);
      } else if (field === "lastEdited") {
        valueA = new Date(a.lastEdited || 0);
        valueB = new Date(b.lastEdited || 0);
      } else {
        valueA = a[field]?.toLowerCase?.() || a[field];
        valueB = b[field]?.toLowerCase?.() || b[field];
      }

      if (valueA < valueB) {
        return sortOrder === "asc" ? -1 : 1;
      } else if (valueA > valueB) {
        return sortOrder === "asc" ? 1 : -1;
      } else {
        return 0;
      }
    });

    setUsers(sortedUsers);
    setSortField(field);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  return (
    <div className="w-full min-h-screen pb-24 pl-8 pr-8 bg-stone-200">
      <div className="h-6 md:h-12"></div>
      <div className="flex flex-col md:flex-row justify-between items-center pb-4">
        <h3 className="text-lg md:text-xl font-semibold">List of Trainers (includes Admin)</h3>
        <p className="text-xs md:text-sm text-gray-500">Click on column headers to sort</p>
        <button
          className="bg-blue-500 text-white text-xs md:text-sm px-3 md:px-4 py-2 rounded-lg mt-2 md:mt-0"
          onClick={() => setTrainerForm(true)}
        >
          Add New Trainer
        </button>
      </div>
      {notification && operationType === "add" && (
        <div className="mb-4 p-4 text-white bg-green-500 rounded flex justify-between items-center">
          <span>{notification}</span>
          <button onClick={() => setNotification("")} className="ml-4">
            ✖
          </button>
        </div>
      )}
      {trainerForm && (
        <TrainerForm
          onSubmit={onSubmit}
          formData={formData}
          onChange={onChange}
          setTrainerForm={setTrainerForm}
          setNotification={setNotification}
          trainerForm={trainerForm}
        />
      )}
      {notification && operationType === "edit" && (
        <div className="mb-4 p-4 text-white bg-green-500 rounded flex justify-between items-center">
          <span>{notification}</span>
          <button onClick={() => setNotification("")} className="ml-4">
            ✖
          </button>
        </div>
      )}
      <div className="overflow-x-auto">
        <table className="table-auto w-full border-collapse bg-white shadow-md rounded-lg">
          <thead>
            <tr className="bg-gray-100 text-gray-700 text-xs md:text-sm">
              <th
                className="border-b border-gray-200 px-2 md:px-6 py-3 text-left cursor-pointer"
                onClick={() => handleSort("name")}
              >
                Name {sortField === "name" && (sortOrder === "asc" ? "↑" : "↓")}
              </th>
              <th
                className="border-b border-gray-200 px-2 md:px-6 py-3 text-left cursor-pointer"
                onClick={() => handleSort("email")}
              >
                Email {sortField === "email" && (sortOrder === "asc" ? "↑" : "↓")}
              </th>
              <th
                className="border-b border-gray-200 px-2 md:px-6 py-3 text-left cursor-pointer"
                onClick={() => handleSort("role")}
              >
                Role {sortField === "role" && (sortOrder === "asc" ? "↑" : "↓")}
              </th>
              <th
                className="border-b border-gray-200 px-2 md:px-6 py-3 text-left cursor-pointer"
                onClick={() => handleSort("dateCreated")}
              >
                Date Joined {sortField === "dateCreated" && (sortOrder === "asc" ? "↑" : "↓")}
              </th>
              <th
                className="border-b border-gray-200 px-2 md:px-6 py-3 text-left cursor-pointer"
                onClick={() => handleSort("lastEdited")}
              >
                Last Edited {sortField === "lastEdited" && (sortOrder === "asc" ? "↑" : "↓")}
              </th>
              <th className="text-left border-b border-gray-200 px-2 md:px-6 py-3">Edit</th>
              <th className="text-left border-b border-gray-200 px-2 md:px-6 py-3">Delete</th>
            </tr>
          </thead>
          <tbody className="text-xs md:text-sm">
            {users.map((user, index) => (
              <tr key={user._id} className={index % 2 === 0 ? "bg-gray-50" : "bg-white hover:bg-gray-50"}>
                <td className="px-2 md:px-6 py-3">
                  {editUser && editUser._id === user._id ? (
                    <input
                      type="text"
                      name="name"
                      value={inlineFormData.name}
                      onChange={onInlineChange}
                      className="w-full p-2 border border-gray-300 rounded"
                    />
                  ) : (
                    user.name
                  )}
                </td>
                <td className="px-2 md:px-6 py-3">
                  {editUser && editUser._id === user._id ? (
                    <input
                      type="email"
                      name="email"
                      value={inlineFormData.email}
                      onChange={onInlineChange}
                      className="w-full p-2 border border-gray-300 rounded"
                    />
                  ) : (
                    user.email
                  )}
                </td>
                <td className="px-2 md:px-6 py-3">
                  {editUser && editUser._id === user._id ? (
                    <select
                      name="role"
                      value={inlineFormData.role}
                      onChange={onInlineChange}
                      className="w-full p-2 border border-gray-300 rounded"
                    >
                      <option value="trainer">Trainer</option>
                      <option value="admin">Admin</option>
                    </select>
                  ) : (
                    user.role
                  )}
                </td>
                <td className="px-2 md:px-6 py-3">
                  {new Date(user.dateCreated).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })}{" "}
                  {new Date(user.dateCreated).toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  })}
                </td>
                <td className="px-2 md:px-6 py-3">
                  {user.lastEdited
                    ? `${new Date(user.lastEdited).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })} ${new Date(user.lastEdited).toLocaleTimeString("en-US", {
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                      })}`
                    : "N/A"}
                </td>
                <td className="px-2 md:px-6 py-3">
                  {editUser && editUser._id === user._id ? (
                    <button className="bg-blue-500 text-white p-1 pl-2 pr-2 rounded-xl" onClick={onSubmit}>
                      Save
                    </button>
                  ) : (
                    <button
                      className="bg-green-500 text-white p-1 pl-2 pr-2 rounded-xl"
                      onClick={() => startEditing(user)}
                    >
                      Edit
                    </button>
                  )}
                </td>
                <td className="px-2 md:px-6 py-3">
                  <button
                    className="bg-red-500 text-white p-1 pl-2 pr-2 rounded-xl"
                    onClick={() => deleteUser(user._id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default Trainers;
