import React from "react";
import CTAbutton from "./CTAbutton";
import image from "../assets/FounderSection/founder.png";

const FounderSection = () => {
  return (
    <div className="bg-stone-200 relative h-[70vh]">
      <img src={image} alt="Founder" className="md:w-full md:h-full object-cover object-top hidden md:block" />
      <div className="absolute inset-0 flex items-center justify-center md:justify-end md:pr-[12rem] md:mt-0">
        <div className="bg-white bg-opacity-70 md:p-10 md:ml-0 md:rounded-3xl max-w-xl md:mx-0">
          {/* Mobile Image with Overlayed Text */}
          <div className="relative md:hidden mb-4">
            <img src={image} alt="Founder" className="w-full h-auto object-cover" />
            <h2 className="absolute inset-0 flex items-center justify-end mr-8 text-2xl font-bold text-white bg-black bg-opacity-10">
              OUR FOUNDER
            </h2>
            <div className="absolute bottom-0 left-0 right-0 flex justify-center p-4">
              <CTAbutton page="founder" title="Learn More" />
            </div>
          </div>

          {/* Original OUR FOUNDER text for larger devices */}
          <h2 className="text-xl md:text-3xl font-bold mb-4 hidden md:block">OUR FOUNDER</h2>

          <p className="text-base md:text-lg text-gray-700 mb-6 font-abel text-justify pt-6 md:pt-0 md:pb-0 pl-8 pr-8 md:pl-4 md:pr-4 md:p-0">
            At HAS Personal Training, we believe that everyone deserves to feel confident and healthy. Whether you're
            just starting your fitness journey or aiming to elevate your training, our comprehensive programs are
            designed to help you reach your goals.
          </p>
          <p className="text-base md:text-lg text-gray-700 mb-6 font-abel text-justify pb-6 md:pb-0 pl-8 pr-8 md:pl-4 md:pr-4  md:p-0">
            Our founder and trainer, Has, is committed to guiding you every step of the way, providing expert support
            and personalized attention to ensure you achieve your fitness aspirations.
          </p>
          <div className="hidden md:flex justify-start">
            <CTAbutton page="founder" title="Learn More" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FounderSection;
