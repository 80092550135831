import React, { useState } from "react";
import api from "../api";
import { useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const ResetPassword = () => {
  const { token } = useParams(); // Retrieve the token from the URL
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [message, setMessage] = useState(null);
  const [error, setError] = useState(null);
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const navigate = useNavigate();

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setPasswordMismatch(true);
      return;
    }
    setPasswordMismatch(false);
    try {
      const res = await api.post(`/api/reset-password/${token}`, {
        newPassword,
      });
      setMessage("Password reset successful! Redirecting to login...");
      setError(null);
      // Redirect to login after a short delay
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (err) {
      setError("Error resetting password. Please try again.");
      setMessage(null);
    }
  };

  return (
    <div className="flex justify-center min-h-screen bg-stone-200">
      <div className="p-6 rounded-lg w-full max-w-sm ">
        <h2 className="text-xl font-bold mb-4 text-center">Reset Password</h2>
        {message && <p className="text-green-500 text-center">{message}</p>}
        {error && <p className="text-red-500 text-center">{error}</p>}
        {passwordMismatch && <p className="text-red-500 text-center">Passwords do not match. Please try again.</p>}
        <form onSubmit={handleResetPassword}>
          <div className="mb-4">
            <label className="block font-bold text-sm mb-2">New Password</label>
            <div className="relative">
              <input
                type={showNewPassword ? "text" : "password"}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                placeholder="Enter new password"
                className="w-full p-2 border border-gray-300 rounded"
                required
              />
              <button
                type="button"
                className="absolute right-2 top-1"
                onClick={() => setShowNewPassword(!showNewPassword)}
              >
                <FontAwesomeIcon icon={showNewPassword ? faEyeSlash : faEye} className="opacity-30 text-sm" />
              </button>
            </div>
          </div>
          <div className="mb-4">
            <label className="block font-bold text-sm mb-2">Confirm Password</label>
            <div className="relative">
              <input
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm new password"
                className="w-full p-2 border border-gray-300 rounded"
                required
              />
              <button
                type="button"
                className="absolute right-2 top-1"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} className="opacity-30 text-sm" />
              </button>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <button type="submit" className="text-white bg-black font-medium text-sm px-5 py-2.5 rounded">
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
