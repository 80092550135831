import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebook, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import TikTokIcon from "../assets/Homepage/tiktok.svg";

const Footer = () => {
  return (
    <footer className="min-h-[3vh] z-20 bottom-0 fixed w-screen bg-gray-800 text-white py-2 md:py-3 font-abel">
      <div className="flex flex-col space-y-1 items-center">
        <div className="md:hidden flex justify-center space-x-4">
          <a href="https://www.instagram.com/haspersonaltraining/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faInstagram} className="text-base" />
          </a>
          <a href="https://www.facebook.com/HaskoiHitman" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faFacebook} className="text-base" />
          </a>
          <a href="https://wa.me/6592312967" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon icon={faWhatsapp} className="text-base" />
          </a>
          <a href="https://www.tiktok.com/@haspersonaltraining" target="_blank" rel="noopener noreferrer">
            <img src={TikTokIcon} alt="TikTok" className="h-5 w-4 md:h-6 md:w-6" />
          </a>
        </div>{" "}
        <div className="text-xs md:text-base">Established in 2020 by Has</div>
      </div>
    </footer>
  );
};

export default Footer;
