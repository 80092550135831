import React, { useEffect, useState } from "react";
import paynow from "../assets/PaymentPage/paynow.png";
import api from "../api";

const PaymentForm = ({ setPaymentForm, selectedCustomer, fetchData }) => {
  const [formData, setFormData] = useState({ amountPaid: "", numOfTrainingsPaidFor: "" });
  const [paymentSuccessPopup, setPaymentSuccessPopup] = useState(false);

  // Apply overflow-hidden to body and scroll to top when modal is open
  useEffect(() => {
    // Scroll to top when the modal opens
    window.scrollTo({ top: 0, behavior: "smooth" });
    // Add overflow-hidden to the body to prevent background scrolling
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);

  // HANDLE INPUT OF PAYMENT FORM
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: name === "amountPaid" || name === "numOfTrainingsPaidFor" ? parseInt(value, 10) : value,
    });
  };

  // HANDLE SUBMISSION OF PAYMENT FORM
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedCustomer) {
      console.error("No customer selected");
      return;
    }
    const dataToSubmit = {
      ...formData,
      customerID: selectedCustomer._id,
      trainerID: selectedCustomer.trainerID,
    };
    console.log("Submitting payment data:", dataToSubmit);
    try {
      const res = await api.post("/api/payments", dataToSubmit);
      console.log("Payment successful", res.data);
      setPaymentSuccessPopup(true);
      fetchData();
      setFormData({ amountPaid: "", numOfTrainingsPaidFor: "" });
    } catch (error) {
      console.error("Error making payment", error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 text-sm md:text-base z-50">
      <div className="relative bg-white p-14 rounded-lg shadow-lg flex flex-col items-center w-11/12 md:w-auto">
        <button
          className="absolute top-10 right-10"
          onClick={() => {
            setPaymentForm(false);
            setPaymentSuccessPopup(false);
          }}
        >
          x
        </button>
        {paymentSuccessPopup ? (
          <div className="my-auto text-center">
            Thank you. <br />
            Payment records have been updated accordingly.
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
            <div className="flex space-x-4">
              <div className="w-1/2">Step 1: Customer to make payment to PayNow QR Code:</div>{" "}
              <img src={paynow} className="w-1/3 pl-2" />
            </div>{" "}
            <div className="h-4"></div>
            <div className="flex items-center space-x-4">
              <label htmlFor="amountPaid" className="font-medium  w-1/2">
                Step 2: Trainer to enter amount customer had just paid:
              </label>
              <input
                type="number"
                name="amountPaid"
                id="amountPaid"
                placeholder=""
                value={formData.amountPaid}
                onChange={handleChange}
                className="border border-gray-400 focus:outline-none placeholder-black placeholder:font-light p-1 pl-2 w-1/2"
              />
            </div>
            <div className="flex items-center space-x-4">
              <label htmlFor="numOfTrainingsPaidFor" className="font-medium  w-1/2">
                Step 3: Trainer to enter number of trainings payment is for:
              </label>
              <input
                type="number"
                name="numOfTrainingsPaidFor"
                id="numOfTrainingsPaidFor"
                placeholder=""
                value={formData.numOfTrainingsPaidFor}
                onChange={handleChange}
                className="border border-gray-400 focus:outline-none placeholder-black placeholder:font-light p-1 pl-2 w-1/2"
              />
            </div>
            <div className="flex items-center space-x-4">
              <label className="font-medium  w-1/2">
                Step 4: Trainer to click to confirm he/she has checked the PayNow receipt:
              </label>
              <button
                className="border border-gray-400 focus:outline-none placeholder-black placeholder:font-light p-1 pl-2 w-1/2 text-left bg-green-500 text-white hover:scale-110 transition duration-300 ease-in-out rounded-md"
                onClick={handleSubmit}
              >
                I have checked the PayNow receipt
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default PaymentForm;
