import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const AdminTrainingChart = ({ filteredTrainings, selectedTrainer }) => {
  // Prepare data for the chart
  const chartData = () => {
    const monthlyCounts = {};

    filteredTrainings.forEach((training) => {
      if (training.status === "completed") {
        const trainerName = training.trainerID.name;
        const monthYear = new Date(training.dateOfTraining).toLocaleString("default", {
          month: "long",
          year: "numeric",
        });

        if (!monthlyCounts[trainerName]) {
          monthlyCounts[trainerName] = {};
        }

        if (!monthlyCounts[trainerName][monthYear]) {
          monthlyCounts[trainerName][monthYear] = 0;
        }

        monthlyCounts[trainerName][monthYear] += 1;
      }
    });

    const months = [
      ...new Set(
        filteredTrainings.map((training) =>
          new Date(training.dateOfTraining).toLocaleString("default", { month: "long", year: "numeric" })
        )
      ),
    ].sort();

    const datasets = [];

    if (selectedTrainer) {
      datasets.push({
        label: selectedTrainer,
        data: months.map((month) => monthlyCounts[selectedTrainer]?.[month] || 0),
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        fill: false,
      });
    } else {
      Object.keys(monthlyCounts).forEach((trainerName) => {
        datasets.push({
          label: trainerName,
          data: months.map((month) => monthlyCounts[trainerName]?.[month] || 0),
          borderColor: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          fill: false,
        });
      });
    }

    return {
      labels: months,
      datasets: datasets,
    };
  };

  return (
    <div className="my-8 ">
      <h3 className="text-lg md:text-xl font-semibold mb-4 text-left ">Number of Completed Trainings by Month</h3>
      <div className="w-full md:w-3/4 lg:w-1/2 mx-auto">
        <Line
          data={chartData()}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: "bottom", // Move legend to the bottom on mobile
                labels: {
                  boxWidth: 12, // Smaller legend box size for mobile
                  font: {
                    size: 10, // Smaller font size for the legend on mobile
                  },
                },
              },
            },
            scales: {
              x: {
                ticks: {
                  maxRotation: 45, // Rotate x-axis labels for better fit
                  minRotation: 0,
                },
              },
            },
          }}
          height={300} // Adjust height for mobile
        />
      </div>
    </div>
  );
};

export default AdminTrainingChart;
