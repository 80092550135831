import React, { useEffect, useState, useCallback } from "react";
import CustomerForm from "./CustomerForm";
import PaymentForm from "./PaymentForm";
import { IoCheckmarkCircle } from "react-icons/io5";
import api from "../api";

const Customers = ({ isAuthenticated, role }) => {
  const [customers, setCustomers] = useState([]);
  const [trainers, setTrainers] = useState([]);
  const [selectedTrainerID, setSelectedTrainerID] = useState("");
  const [notification, setNotification] = useState("");
  const [formData, setFormData] = useState({ name: "", email: "", phone: "", dateOfBirth: "", ic: "", sex: "" });
  const [inlineFormData, setInlineFormData] = useState({
    name: "",
    email: "",
    phone: "",
    dateOfBirth: "",
    ic: "",
    sex: "",
  });
  const [editCustomer, setEditCustomer] = useState(null);
  const [operationType, setOperationType] = useState(null);
  const [customerForm, setCustomerForm] = useState(false);
  const [paymentForm, setPaymentForm] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [expandedCustomer, setExpandedCustomer] = useState(null);
  const [sortField, setSortField] = useState("dateJoined"); // State to track the current sort field
  const [sortOrder, setSortOrder] = useState("asc"); // State to track the current sort order

  // FETCH CUSTOMERS AND USERS(TRAINERS) DATA
  const fetchData = useCallback(async () => {
    try {
      if (role === "trainer") {
        const customersRes = await api.get("/api/customers");
        console.log(customersRes);
        setCustomers(customersRes.data);
      } else {
        const [customersRes, trainersRes] = await Promise.all([api.get("/api/customers"), api.get("/api/users")]);
        setCustomers(customersRes.data);
        setTrainers(trainersRes.data);
      }
    } catch (err) {
      console.error(err.response.data);
    }
  }, [role, inlineFormData]); // Only change when `role` changes

  useEffect(() => {
    fetchData();
  }, [fetchData, isAuthenticated, editCustomer]);

  // CALCULATE AGE
  function calculateAge(dateOfBirth) {
    const diff = Date.now() - new Date(dateOfBirth).getTime();
    const ageDate = new Date(diff);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  // EDIT DETAILS OF EXISTING CUSTOMER
  const startEditing = (customer) => {
    setEditCustomer(customer);
    setInlineFormData(customer);
    setOperationType(null);
  };
  const onInlineChange = (e) => setInlineFormData({ ...inlineFormData, [e.target.name]: e.target.value });
  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  // HANDLE SUBMISSION OF ADD NEW CUSTOMER OR EDIT CUSTOMER
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const dataToSubmit = { ...formData };

      // Include `trainerID` if the user is an admin
      if (role === "admin" && selectedTrainerID) {
        dataToSubmit.trainerID = selectedTrainerID;
      }
      let res;
      if (editCustomer) {
        res = await api.put(`/api/customers/${editCustomer._id}`, inlineFormData);
        // below updates the 'customers' state to the new array generated by 'map'
        setCustomers(customers.map((customer) => (customer._id === editCustomer._id ? res.data : customer))); // if true, returns res.data, else returns customer object
        setNotification("Success! Customer has been updated.");
        setOperationType("edit");
      } else {
        res = await api.post("/api/customers", dataToSubmit);
        setCustomers([...customers, res.data]);
        setNotification("Success! Customer has been added.");
        setOperationType("add");
      }
      setCustomerForm(false);
      setFormData({ name: "", email: "", phone: "", dateOfBirth: "", ic: "", sex: "" });
      setInlineFormData({ name: "", email: "", phone: "", dateOfBirth: "", ic: "", sex: "" });
      setEditCustomer(null);
      setSelectedTrainerID("");
      window.scrollTo({ top: 0, behavior: "smooth" });
    } catch (err) {
      console.error(err.response.data);
      setNotification("Failed to add/update customer, please try again.");
    }
  };

  // USE EFFECT TO AUTO-CLOSE NOTIFICATION
  useEffect(() => {
    if (notification) {
      const timeout = setTimeout(() => {
        setNotification("");
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [notification]);

  // SORTING FUNCTION
  const handleSort = (field) => {
    const sortedCustomers = [...customers].sort((a, b) => {
      let valueA, valueB;
      if (field === "age") {
        valueA = calculateAge(a.dateOfBirth);
        valueB = calculateAge(b.dateOfBirth);
      } else if (field === "dateJoined") {
        valueA = new Date(a.dateJoined);
        valueB = new Date(b.dateJoined);
      } else if (field === "dateOfBirth") {
        valueA = new Date(a.dateOfBirth);
        valueB = new Date(b.dateOfBirth);
      } else if (field === "paymentHistory") {
        const lastPaymentA = a.paymentHistory?.length > 0 ? new Date(a.paymentHistory[0].dateOfPayment) : new Date(0);
        const lastPaymentB = b.paymentHistory?.length > 0 ? new Date(b.paymentHistory[0].dateOfPayment) : new Date(0);
        valueA = lastPaymentA;
        valueB = lastPaymentB;
      } else if (field === "trainerID") {
        valueA = a.trainerID?.name?.toLowerCase() || "";
        valueB = b.trainerID?.name?.toLowerCase() || "";
      } else {
        valueA = a[field]?.toLowerCase?.() || a[field];
        valueB = b[field]?.toLowerCase?.() || b[field];
      }
      if (valueA < valueB) {
        return sortOrder === "asc" ? -1 : 1;
      } else if (valueA > valueB) {
        return sortOrder === "asc" ? 1 : -1;
      } else {
        return 0;
      }
    });
    setCustomers(sortedCustomers);
    setSortField(field);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  return (
    <div className="w-full min-h-screen pb-24 px-4 md:px-8 bg-stone-200">
      <div className="h-6 md:h-12"></div>
      <div className="flex flex-col md:flex-row justify-between items-center pb-4">
        <h3 className="text-lg md:text-xl font-semibold">List of Customers</h3>
        <p className="text-xs md:text-sm text-gray-500">Click on column headers to sort</p>
        <button
          className="bg-blue-500 text-white text-xs md:text-sm px-3 md:px-4 py-2 rounded-lg mt-2 md:mt-0"
          onClick={() => setCustomerForm(true)}
        >
          Add New Customer
        </button>
      </div>
      {notification && (
        <div className="mb-4 p-4 text-white bg-green-500 rounded flex justify-between items-center">
          <span>{notification}</span>
          <button onClick={() => setNotification("")} className="ml-4">
            ✖
          </button>
        </div>
      )}
      {/* TABLE */}{" "}
      <div className="overflow-x-auto">
        <table className="table-auto w-full border-collapse bg-white shadow-md rounded-lg">
          <thead>
            <tr className="bg-gray-100 text-gray-700 text-xs md:text-sm">
              <th
                className="border-b border-gray-200 px-2 md:px-6 py-3 text-left cursor-pointer"
                onClick={() => handleSort("name")}
              >
                Name {sortField === "name" && (sortOrder === "asc" ? "↑" : "↓")}
              </th>
              <th
                className="border-b border-gray-200 px-2 md:px-6 py-3 text-left cursor-pointer"
                onClick={() => handleSort("email")}
              >
                Email {sortField === "email" && (sortOrder === "asc" ? "↑" : "↓")}
              </th>
              <th
                className="border-b border-gray-200 px-2 md:px-6 py-3 text-left cursor-pointer"
                onClick={() => handleSort("phone")}
              >
                Phone {sortField === "phone" && (sortOrder === "asc" ? "↑" : "↓")}
              </th>
              <th
                className="border-b border-gray-200 px-2 md:px-6 py-3 text-left cursor-pointer"
                onClick={() => handleSort("age")}
              >
                Date of Birth {sortField === "age" && (sortOrder === "asc" ? "↑" : "↓")}
              </th>
              <th
                className="border-b border-gray-200 px-2 md:px-6 py-3 text-left cursor-pointer"
                onClick={() => handleSort("sex")}
              >
                Sex {sortField === "sex" && (sortOrder === "asc" ? "↑" : "↓")}
              </th>
              <th
                className="border-b border-gray-200 px-2 md:px-6 py-3 text-left cursor-pointer"
                onClick={() => handleSort("dateJoined")}
              >
                Date Joined {sortField === "dateJoined" && (sortOrder === "asc" ? "↑" : "↓")}
              </th>
              <th
                className="border-b border-gray-200 px-2 md:px-6 py-3 text-left cursor-pointer"
                onClick={() => handleSort("numOfTrainings")}
              >
                Trainings Left {sortField === "numOfTrainings" && (sortOrder === "asc" ? "↑" : "↓")}
              </th>
              <th className="border-b border-gray-200 px-2 md:px-6 py-3">Make Payment</th>

              {role === "admin" && (
                <th
                  className="border-b border-gray-200 px-2 md:px-6 py-3 text-left cursor-pointer"
                  onClick={() => handleSort("trainerID")}
                >
                  Trainer {sortField === "trainerID" && (sortOrder === "asc" ? "↑" : "↓")}
                </th>
              )}
              <th className="border-b border-gray-200 px-2 md:px-6 py-3">Edit</th>
              <th
                className="border-b border-gray-200 px-2 md:px-6 py-3 text-left cursor-pointer"
                onClick={() => handleSort("paymentHistory")}
              >
                Payment History {sortField === "paymentHistory" && (sortOrder === "asc" ? "↑" : "↓")}
              </th>
            </tr>
          </thead>
          <tbody className="text-xs md:text-sm">
            {customers.map((customer, index) => (
              <tr key={customer._id} className={index % 2 === 0 ? "bg-gray-50" : "bg-white hover:bg-gray-50"}>
                <td className="px-2 md:px-6 py-3">
                  {editCustomer && editCustomer._id === customer._id ? (
                    <input
                      type="text"
                      name="name"
                      value={inlineFormData.name}
                      onChange={onInlineChange}
                      className="w-full p-2 border border-gray-300 rounded"
                    />
                  ) : (
                    customer.name
                  )}
                </td>
                <td className="px-2 md:px-6 py-3 break-words">
                  {editCustomer && editCustomer._id === customer._id ? (
                    <input
                      type="email"
                      name="email"
                      value={inlineFormData.email}
                      onChange={onInlineChange}
                      className="w-full p-2 border border-gray-300 rounded"
                    />
                  ) : (
                    customer.email
                  )}
                </td>
                <td className="px-2 md:px-6 py-3">
                  {editCustomer && editCustomer._id === customer._id ? (
                    <input
                      type="number"
                      name="phone"
                      value={inlineFormData.phone}
                      onChange={onInlineChange}
                      className="w-full p-2 border border-gray-300 rounded"
                    />
                  ) : (
                    customer.phone
                  )}
                </td>
                <td className="px-2 md:px-6 py-3">
                  {editCustomer && editCustomer._id === customer._id ? (
                    <input
                      type="date"
                      name="dateOfBirth"
                      value={new Date(inlineFormData.dateOfBirth).toISOString().split("T")[0]} // Format for date input field
                      onChange={onInlineChange}
                      className="w-full p-2 border border-gray-300 rounded bg-white appearance-none h-10"
                    />
                  ) : (
                    new Date(customer.dateOfBirth).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                    })
                  )}
                </td>

                <td className="px-2 md:px-6 py-3">
                  {editCustomer && editCustomer._id === customer._id ? (
                    <input
                      type="text"
                      name="sex"
                      value={inlineFormData.sex}
                      onChange={onInlineChange}
                      className="w-full p-2 border border-gray-300 rounded"
                    />
                  ) : (
                    customer.sex
                  )}
                </td>
                <td className="px-2 md:px-6 py-3">
                  {new Date(customer.dateJoined).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })}
                </td>
                <td className="px-2 md:px-6 py-3">{customer.numOfTrainings}</td>
                <td className="px-2 md:px-6 py-3">
                  <button
                    className="bg-green-500 p-2 rounded-xl text-white hover:scale-105 transition-transform duration-300"
                    onClick={() => {
                      setSelectedCustomer(customer);
                      setPaymentForm(true);
                    }}
                  >
                    PayNow
                  </button>
                </td>
                <td className="px-2 md:px-6 py-3">
                  {editCustomer && editCustomer._id === customer._id ? (
                    <button
                      className="bg-blue-500 text-white p-2 rounded-xl hover:scale-105 transition-transform duration-300"
                      onClick={onSubmit}
                    >
                      Save
                    </button>
                  ) : (
                    <button
                      className="bg-green-500 text-white p-2 rounded-xl hover:scale-105 transition-transform duration-300"
                      onClick={() => startEditing(customer)}
                    >
                      Edit
                    </button>
                  )}
                </td>
                <td className="px-2 md:px-6 py-3">
                  {customer.paymentHistory && customer.paymentHistory?.length === 0 ? (
                    <div>No Payment History</div>
                  ) : (
                    <div className="overflow-hidden transition-all duration-300 mt-2">
                      {(customer.paymentHistory || [])
                        .sort((a, b) => new Date(b.dateOfPayment) - new Date(a.dateOfPayment))
                        .slice(0, expandedCustomer === customer._id ? customer?.paymentHistory?.length : 1)
                        .map((payment, index) => (
                          <div className="text-xs md:text-sm flex" key={index}>
                            <div className=" pr-2">
                              <IoCheckmarkCircle className="text-base" />
                            </div>
                            <div>
                              Date: {new Date(payment.dateOfPayment).toLocaleDateString()}
                              <br />
                              Time:{" "}
                              {new Date(payment.dateOfPayment).toLocaleTimeString([], {
                                hour: "2-digit",
                                minute: "2-digit",
                              })}
                              <br />
                              Amount Paid: ${payment.amountPaid}
                              <br />
                              Number of Trainings Paid For: {payment.numOfTrainingsPaidFor}
                            </div>
                          </div>
                        ))}
                      {customer?.paymentHistory?.length > 3 && (
                        <button
                          className="text-blue-500 underline mt-2"
                          onClick={() => setExpandedCustomer(expandedCustomer === customer._id ? null : customer._id)}
                        >
                          {expandedCustomer === customer._id ? "− Show Less" : "+ Show More"}
                        </button>
                      )}
                    </div>
                  )}
                </td>
                {role === "admin" && (
                  <td className="px-2 md:px-6 py-3">
                    {editCustomer && editCustomer._id === customer._id && role === "admin" ? (
                      <select
                        name="trainerID"
                        value={inlineFormData.trainerID}
                        onChange={onInlineChange}
                        className="w-full p-2 border border-gray-300 rounded"
                      >
                        <option value="">Select Trainer</option>
                        {trainers.map((trainer) => (
                          <option key={trainer._id} value={trainer._id}>
                            {trainer.name}
                          </option>
                        ))}
                      </select>
                    ) : (
                      customer.trainerID?.name || "N/A"
                    )}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* FORM: ADD CUSTOMER */}
      {customerForm && (
        <CustomerForm
          setNotification={setNotification}
          formData={formData}
          onChange={onChange}
          onSubmit={onSubmit}
          trainers={trainers}
          setCustomerForm={setCustomerForm}
          role={role}
          selectedTrainerID={selectedTrainerID}
          setSelectedTrainerID={setSelectedTrainerID}
          customer={customerForm}
        />
      )}
      {/* FORM: PAYMENT */}
      {paymentForm && (
        <PaymentForm setPaymentForm={setPaymentForm} selectedCustomer={selectedCustomer} fetchData={fetchData} />
      )}
    </div>
  );
};
export default Customers;
