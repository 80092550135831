// axios is a promise-based HTTP client for making requests to a server
// HOW IT WORKS:
// // // When a user logs in and you receive a token
// const token = 'some-auth-token';
// setAuthToken(token);
// // Now all Axios requests will include the token in the 'x-auth-token' header
// // To remove the token when the user logs out
// setAuthToken(null);
// // Axios requests will no longer include the 'x-auth-token' header

import axios from "axios";
const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }
};

export default setAuthToken;
