import React from "react";
import gym1 from "../assets/GymSection/gym1.jpeg";
import gym2 from "../assets/GymSection/gym2.jpeg";
import gym5 from "../assets/GymSection/gym5.jpeg";

const GymSection = () => {
  return (
    <div className="w-screen md:h-[63vh] bg-stone-200">
      <div className="flex items-center">
        <div className="hidden md:block w-1/2">
          <img
            src={gym5}
            className="md:w-[32rem] md:h-[32rem] object-cover mx-auto md:pt-16 md:opacity-80 opacity-15"
          />
        </div>
        <div className="pl-8 pr-8 pb-6 pt-8 md:pt-14 w-full md:w-1/2 flex flex-col space-y-4">
          <div className="">
            <h2 className="text-xl md:text-3xl font-bold">OUR STATE-OF-THE-ART FACILITY</h2>
          </div>
          <div className="md:w-3/4 text-justify text-base md:text-lg font-abel">
            {" "}
            Experience the pinnacle of fitness at our cutting-edge gym, equipped with the latest technology and a
            vibrant, motivating atmosphere. Our modern, sleek environment provides everything you need to excel and
            achieve your fitness goals. We are conveniently located in the heart of the CBD area.
          </div>
          <div className="hidden md:block">
            {" "}
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.825913026644!2d103.8472731750827!3d1.2779534987098995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19f651759251%3A0xf09846e1576b66e7!2sFreedom%20Gym!5e0!3m2!1sen!2ssg!4v1718653048701!5m2!1sen!2ssg"
              width="550"
              height="300"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
        {/* <div className="absolute inset-0 flex items-top justify-center md:justify-end pr-4 md:pr-[12rem] md:mt-[2rem]">
          <div className="md:p-4 rounded-lg max-w-xl mt-[-1rem] mx-4 md:mx-0">
            <div>
              <h2 className="mt-14 md:mt-[-1rem] text-xl md:text-3xl font-bold mb3">OUR STATE-OF-THE-ART FACILITY</h2>
              <p className="md:text-gray-700 text-base md:text-lg text-justify mb-4 font-abel">
                Experience the pinnacle of fitness at our cutting-edge gym, equipped with the latest technology and a
                vibrant, motivating atmosphere. Our modern, sleek environment provides everything you need to excel and
                achieve your fitness goals. We are conveniently located in the heart of the CBD area.
              </p>
            </div>

            <div className="w-full hidden md:block">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.825913026644!2d103.8472731750827!3d1.2779534987098995!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da19f651759251%3A0xf09846e1576b66e7!2sFreedom%20Gym!5e0!3m2!1sen!2ssg!4v1718653048701!5m2!1sen!2ssg"
                width="400"
                height="250"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div> */}
      </div>

      {/* Images for Mobile Devices */}
      <div className="md:hidden space-y-4 px-4">
        <img src={gym5} alt="Gym 5" className="w-full h-auto object-cover rounded-md opacity-80" />
        <img src={gym1} alt="Gym 1" className="w-full h-auto object-cover rounded-md opacity-80" />
        <img src={gym2} alt="Gym 2" className="w-full h-auto object-cover rounded-md opacity-80" />
      </div>
    </div>
  );
};

export default GymSection;
