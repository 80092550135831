import React, { useState } from "react";
import api from "../api";
import { useNavigate } from "react-router-dom";
import setAuthToken from "../services/authService";

const Register = ({ setIsAuthenticated, setRole, user }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    role: "trainer", // Default role
  });
  // const [registerSuccess, setRegisterSuccess] = useState(false);
  const [registerError, setRegisterError] = useState(false);

  // Destructures from formData for easy access
  const { name, email, password, role } = formData;
  const navigate = useNavigate();

  // e.target.name: Refers to the name attribute of the input element being changed.
  // e.target.value: Refers to the value of the input element being changed.
  // setFormData: Updates the formData state with the new value while keeping the rest of the data intact.
  const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

  // axios.post: Sends a POST request to the /api/auth/login endpoint with formData.
  // localStorage.setItem('token', res.data.token): Stores the received token in localStorage.
  // history.push('/dashboard'): Redirects the trainer to the /dashboard route upon successful login.
  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await api.post("/api/register", formData);
      console.log(res.data.user);
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("name", res.data.user.name);
      localStorage.setItem("role", res.data.user.role);
      // setRegisterSuccess(true);
      setAuthToken(res.data.token);
      setIsAuthenticated(true);
      setRole(res.data.user.role);
      navigate(res.data.user.role === "admin" ? "/admindashboard" : "/trainerdashboard");
    } catch (err) {
      console.error(err.response.data);
      setRegisterError(true);
    }
  };

  return (
    <div className="flex justify-center h-screen bg-stone-200">
      <div className="p-6 rounded-lg w-full max-w-sm">
        <form onSubmit={onSubmit} className="flex flex-col space-y-8">
          <div className="">
            <label className="block font-bold text-sm mb-2">NAME </label>
            <input
              type="text"
              name="name"
              value={name}
              onChange={onChange}
              placeholder="Name"
              required
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>

          <div className="">
            <label className="block font-bold text-sm mb-2">EMAIL ADDRESS </label>
            <input
              type="email"
              name="email"
              value={email}
              onChange={onChange}
              placeholder="Email"
              required
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="">
            <label className="block font-bold text-sm mb-2">PASSWORD </label>
            <input
              type="password"
              name="password"
              value={password}
              onChange={onChange}
              placeholder="Password"
              required
              className="w-full p-2 border border-gray-300 rounded"
            />
          </div>
          <div className="relative">
            <label className="block font-bold text-sm mb-2">SELECT ROLE </label>
            <select
              name="role"
              value={role}
              onChange={onChange}
              className="w-full p-2 border border-gray-300 rounded bg-white appearance-none text-gray-400"
              style={{ WebkitAppearance: "none", MozAppearance: "none" }}
            >
              <option value="trainer">Trainer</option>
              <option value="admin">Admin</option>
            </select>
            {/* Optional: Custom Arrow */}
            <div className="absolute right-3 top-9 pointer-events-none text-gray-400">▼</div>
          </div>
          <div className="pt-4 flex items-center justify-center">
            <button className="text-white bg-black font-medium text-sm px-5 py-2.5 rounded" name="login">
              REGISTER
            </button>
          </div>
        </form>

        <div className="mt-4 text-center">
          {/* {registerSuccess && <p className="text-green-400">Successfully Registered!</p>} */}
          {registerError && <p className="text-red-400">Registration Failed, Please Try Again!</p>}
        </div>
      </div>
    </div>
  );
};

export default Register;
