import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Logo from "../assets/Homepage/haslogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes, faUser } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faFacebook, faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import TikTokIcon from "../assets/Homepage/blacktiktok.svg";

const NavBar = ({ isAuthenticated, handleLogout, role, user }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  const toggleProfileMenu = () => {
    setIsProfileOpen(!isProfileOpen);
  };

  return (
    <div className="font-bold w-screen bg-stone-200 pt-2 md:pt-0">
      <nav className="relative">
        <div className="flex justify-between items-center px-4">
          <NavLink to="/" onClick={handleLinkClick} className="md:hidden flex-grow-0 flex-shrink-0">
            <img src={Logo} alt="Logo" className="h-12 mx-auto" />
          </NavLink>
          <button className="md:hidden text-2xl" onClick={toggleMenu}>
            <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
          </button>
        </div>
        <ul
          className={`${
            isOpen ? "fixed inset-0 bg-white z-50 p-4" : "hidden"
          } md:flex h-full list-none items-center justify-between space-y-4 md:space-y-0 px-4 md:px-12 md:mb-0 md:pr-[4rem] md:pl-[4rem] md:text-lg border-b-[0.1rem] border-black border-opacity-30`}
        >
          {/* Close button inside the menu overlay */}
          <button className="md:hidden absolute top-4 right-4 text-2xl" onClick={toggleMenu}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
          {/* Logo inside the menu overlay */}
          <li className="md:hidden mb-8">
            <NavLink to="/" onClick={handleLinkClick} className="flex justify-center">
              <img src={Logo} alt="Logo" className="h-16" />
            </NavLink>
          </li>
          <li>
            <div className="flex flex-col items-center">
              <NavLink to="/" onClick={handleLinkClick}>
                <img src={Logo} alt="Logo" className="h-12 md:h-[6rem] md:block hidden" />
              </NavLink>
              {/* {role === "admin" || role === "trainer" ? (
                <div>
                  <div className="text-black font-light text-xs text-center">
                    Welcome Back,
                    <br />
                    {user.name}
                  </div>
                </div>
              ) : null} */}
            </div>
          </li>
          {isAuthenticated ? (
            <>
              {role === "admin" && (
                <>
                  <li>
                    <NavLink
                      to="/admindashboard"
                      onClick={handleLinkClick}
                      className={({ isActive }) => (isActive ? "underline underline-offset-2 font-bold" : "")}
                    >
                      DASHBOARD
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/trainers"
                      onClick={handleLinkClick}
                      className={({ isActive }) => (isActive ? "underline underline-offset-2 font-bold" : "")}
                    >
                      TRAINERS
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/trainings"
                      onClick={handleLinkClick}
                      className={({ isActive }) => (isActive ? "underline underline-offset-2 font-bold" : "")}
                    >
                      TRAININGS
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/payments"
                      onClick={handleLinkClick}
                      className={({ isActive }) => (isActive ? "underline underline-offset-2 font-bold" : "")}
                    >
                      PAYMENTS
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/customers"
                      onClick={handleLinkClick}
                      className={({ isActive }) => (isActive ? "underline underline-offset-2 font-bold" : "")}
                    >
                      CUSTOMERS
                    </NavLink>
                  </li>
                </>
              )}
              {role === "trainer" && (
                <>
                  <li>
                    <NavLink
                      to="/trainerdashboard"
                      onClick={handleLinkClick}
                      className={({ isActive }) => (isActive ? "underline underline-offset-2 font-bold" : "")}
                    >
                      DASHBOARD
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/trainings"
                      onClick={handleLinkClick}
                      className={({ isActive }) => (isActive ? "underline underline-offset-2 font-bold" : "")}
                    >
                      TRAININGS
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/payments"
                      onClick={handleLinkClick}
                      className={({ isActive }) => (isActive ? "underline underline-offset-2 font-bold" : "")}
                    >
                      PAYMENTS
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/customers"
                      onClick={handleLinkClick}
                      className={({ isActive }) => (isActive ? "underline underline-offset-2 font-bold" : "")}
                    >
                      CUSTOMERS
                    </NavLink>
                  </li>
                </>
              )}
              {/* Profile Dropdown */}
              <li className="relative">
                <button className="flex items-center space-x-2" onClick={toggleProfileMenu}>
                  <FontAwesomeIcon icon={faUser} className="text-lg" />
                  <span>{user.name}</span>
                </button>

                {isProfileOpen && (
                  <div className="absolute left-0 mt-2 bg-white shadow-md rounded-md py-2 w-48 z-50">
                    <NavLink
                      to="/profile"
                      className="block px-4 py-2 text-sm hover:bg-gray-100"
                      onClick={() => {
                        toggleProfileMenu();
                        handleLinkClick();
                      }}
                    >
                      View Profile
                    </NavLink>
                    <button
                      onClick={() => {
                        handleLogout();
                        toggleProfileMenu();
                        handleLinkClick();
                      }}
                      className="block w-full text-left px-4 py-2 text-sm hover:bg-gray-100"
                    >
                      Logout
                    </button>
                  </div>
                )}
              </li>
            </>
          ) : (
            <>
              <li>
                <NavLink
                  to="/founder"
                  onClick={handleLinkClick}
                  className={({ isActive }) => (isActive ? "underline underline-offset-2 font-bold" : "")}
                >
                  FOUNDER
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/bodytransformation"
                  onClick={handleLinkClick}
                  className={({ isActive }) => (isActive ? "underline underline-offset-2 font-bold" : "")}
                >
                  BODY TRANSFORMATION PROGRAM
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/weightloss"
                  onClick={handleLinkClick}
                  className={({ isActive }) => (isActive ? "underline underline-offset-2 font-bold" : "")}
                >
                  WEIGHT LOSS PROGRAM
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/login"
                  onClick={handleLinkClick}
                  className={({ isActive }) => (isActive ? "underline underline-offset-2 font-bold" : "")}
                >
                  LOGIN
                </NavLink>
              </li>
            </>
          )}
          {role === "admin" || role === "trainer" ? null : (
            <li>
              <div className="hidden container mx-auto text-center mt-3">
                <div className="flex justify-center space-x-6 mb-4">
                  <a href="https://www.instagram.com/haspersonaltraining/" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faInstagram} size="1x" />
                  </a>
                  <a href="https://www.facebook.com/HaskoiHitman" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faFacebook} size="1x" />
                  </a>
                  <a href="https://wa.me/6592312967" target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={faWhatsapp} size="1x" />
                  </a>
                  <a href="https://www.tiktok.com/@haspersonaltraining" target="_blank" rel="noopener noreferrer">
                    <img src={TikTokIcon} alt="TikTok" className="h-4 w-4 md:h-5 md:w-5 mt-1" />
                  </a>
                </div>
              </div>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default NavBar;
