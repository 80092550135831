import React, { useState, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Tooltip from "react-tooltip";

const TrainingCalendar = ({
  setFormData,
  setIsModalOpen,
  formatDate,
  handleEventClick,
  now,
  formData,
  filteredTrainings,
}) => {
  const calendarRef = useRef(null);
  const [tooltipContent, setTooltipContent] = useState("");
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedDate, setSelectedDate] = useState(new Date()); // Track both month and year with a Date object
  const [isMonthSelectorOpen, setIsMonthSelectorOpen] = useState(false);
  // HANDLE MONTH CHANGE
  const handleMonthChange = (e) => {
    const month = parseInt(e.target.value, 10);
    setSelectedMonth(month);

    // Safely access the FullCalendar API
    if (calendarRef.current) {
      const calendarApi = calendarRef.current.getApi();
      if (calendarApi) {
        calendarApi.gotoDate(new Date(now.getFullYear(), month, 1));
      } else {
        console.error("Calendar API is not available.");
      }
    } else {
      console.error("Calendar reference is not available.");
    }
  };

  // CREATE A NEW TRAINING
  const handleDateClick = (arg) => {
    setFormData({ ...formData, date: formatDate(arg.dateStr) });
    setIsModalOpen(true);
  };

  // Handle month and year changes with the datesSet callback
  const handleDatesSet = () => {
    const calendarApi = calendarRef.current?.getApi();
    if (calendarApi) {
      const newDate = calendarApi.getDate(); // Get the current date from the calendar API
      setSelectedDate(newDate);
    }
  };

  // Handle mouse enter for event to show tooltip
  const handleEventMouseEnter = (info) => {
    const { clientX, clientY } = info.jsEvent;
    setTooltipContent(`${info.event.title} - ${info.event.extendedProps.status}`);
    setTooltipPosition({ top: clientY + 10, left: clientX + 10 });
    setTooltipVisible(true);
  };

  // Handle mouse leave for event to hide tooltip
  const handleEventMouseLeave = () => {
    setTooltipVisible(false);
    setTooltipContent("");
  };

  return (
    <div>
      <div className="p-4 bg-white rounded-lg shadow-lg text-xs md:text-sm">
        <div className="pb-4 flex space-x-4">
          <label className="block font-semibold text-sm md:text-lg pt-1 pb-2">Month:</label>
          <div className="md:w-1/4 ">
            <select
              value={selectedMonth}
              onChange={handleMonthChange}
              className="p-2 border border-gray-300 rounded-lg text-base"
            >
              {Array.from({ length: 12 }, (_, index) => (
                <option key={index} value={index}>
                  {new Date(0, index).toLocaleString("en-US", { month: "long" })}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="text-left mb-2 text-sm md:text-base font-semibold">
          {selectedDate.toLocaleString("en-US", {
            year: "numeric",
            month: "short",
          })}
        </div>{" "}
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="timeGridWeek"
          slotMinTime="06:00:00"
          slotMaxTime="23:00:00"
          firstDay={1} // Start week on Monday (0 = Sunday, 1 = Monday)
          height="auto"
          headerToolbar={{
            left: "prev,next",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          titleFormat={{
            year: "numeric",
            month: "short",
          }}
          dayHeaderContent={(dateInfo) => (
            <div className="flex flex-col items-center">
              <span className="text-xs font-light">{dateInfo.text}</span>
              <span className="text-xs font-light">{dateInfo.date.getDate()}</span>
            </div>
          )}
          dayHeaderFormat={{ weekday: "narrow" }} // Display day headers as M T W T F S S
          events={filteredTrainings
            .map((training) => {
              if (!training.dateOfTraining || isNaN(new Date(training.dateOfTraining).getTime())) {
                return null; // Skip invalid dates
              }
              const startDate = new Date(training.dateOfTraining);
              const endDate = new Date(startDate);
              endDate.setHours(startDate.getHours() + 1);
              return {
                id: training?._id,
                title: `${training.customerID?.name} (${training.status})`,
                start: startDate.toISOString(),
                end: endDate.toISOString(),
                allDay: false,
                extendedProps: {
                  status: training.status,
                  customerName: training.customerID?.name,
                  trainerName: training.trainerID?.name,
                },
              };
            })
            .filter((event) => event !== null)}
          dateClick={handleDateClick}
          eventClick={handleEventClick}
          datesSet={handleDatesSet}
          eventMouseEnter={handleEventMouseEnter}
          eventMouseLeave={handleEventMouseLeave}
          eventColor="#3182CE"
          eventTextColor="#FFFFFF"
          dayMaxEventRows={2}
          slotLabelFormat={{ hour: "numeric", hour12: true }}
          slotLabelContent={(arg) => {
            // Use the toLocaleString method and convert the AM/PM to lowercase
            const formattedTime = arg.date
              .toLocaleString("en-US", {
                hour: "numeric",

                hour12: true,
              })
              .replace("AM", "am")
              .replace("PM", "pm");

            return formattedTime;
          }}
          views={{
            timeGridDay: {
              eventLimit: true,
              slotLabelInterval: "01:00",
            },
            timeGridWeek: {
              slotLabelInterval: "01:00", // Set interval to 1 hour
              allDaySlot: false,
            },
          }}
          eventDisplay="block"
          slotDuration="01:00:00"
          className="mobile-calendar small-title text-xs"
        />
        {tooltipVisible && (
          <div
            className="absolute z-10 bg-white p-2 shadow-lg border border-gray-300 rounded-lg text-xs"
            style={{
              top: tooltipPosition.top + 100, // Adjust this value to move the tooltip closer vertically
              left: tooltipPosition.left - 5, // Adjust this value to move the tooltip closer horizontally
            }}
          >
            {tooltipContent}
          </div>
        )}
      </div>
    </div>
  );
};

export default TrainingCalendar;
