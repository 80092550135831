import React from "react";
import videoSrc from "../assets/BodyTransformationPage/BodyTransformation.mp4";
import btVideoSrc from "../assets/BodyTransformationPage/BTvideo.mp4";
import image1 from "../assets/BodyTransformationPage/BT1.png";

const BodyTransformation = () => {
  return (
    <div className="min-h-screen w-screen bg-stone-200">
      {/* LARGE SCREEN */}
      <div className="hidden md:flex justify-center items-center h-full">
        <div className="relative h-[38rem] w-full rounded">
          <div className="relative h-[40rem] w-full">
            <video
              className="absolute left-0 w-full h-full object-cover object-bottom hidden md:block"
              src={videoSrc}
              autoPlay
              loop
              muted
            ></video>
            <div className="relative z-10 flex flex-col items-start text-justify justify-center h-full text-white pl-8 w-[40rem] ml-[50rem] ">
              <h1 className="text-2xl md:text-3xl font-bold mb-4 md:ml-10">OVERVIEW</h1>
              <p className="text-xl md:text-xl mb-8 font-abel md:ml-10">
                Our Body Transformation Program is designed to help you achieve a complete physical overhaul. This
                program is perfect for individuals looking to build muscle, improve strength, and achieve a more
                sculpted and toned physique.
              </p>
              <h1 className="text-2xl md:text-3xl font-bold mb-4 md:ml-10">GOALS</h1>{" "}
              <p className="text-xl md:text-xl font-abel md:ml-10">
                <ul className="list-disc pl-5 text-justify">
                  <li>Increase muscle mass</li>
                  <li>Enhance overall strength</li>
                  <li>Improve muscle definition</li>
                  <li>Boost endurance and performance</li>
                  <li>Develop a balanced and proportionate body</li>
                </ul>
              </p>
            </div>
          </div>{" "}
        </div>
      </div>
      {/* SMALL SCREEN */}
      <div className="block md:hidden p-4 text-black">
        <h1 className="text-2xl md:text-3xl font-bold mb-4">OVERVIEW</h1>
        <p className="text-lg md:text-xl mb-8 font-abel">
          Our Body Transformation Program is designed to help you achieve a complete physical overhaul. This program is
          perfect for individuals looking to build muscle, improve strength, and achieve a more sculpted and toned
          physique.
        </p>
        <h1 className="text-2xl md:text-3xl font-bold mb-4">GOALS</h1>
        <ul className="list-disc pl-5 text-lg md:text-xl font-abel">
          <li>Increase muscle mass</li>
          <li>Enhance overall strength</li>
          <li>Improve muscle definition</li>
          <li>Boost endurance and performance</li>
          <li>Develop a balanced and proportionate body</li>
        </ul>
      </div>
      {/* SMALL SCREEN: VIDEO */}
      <div className="relative h-[50vh] w-full block md:hidden">
        <video
          className="absolute top-5 left-0 w-full h-full object-cover object-bottom"
          src={btVideoSrc}
          autoPlay
          loop
          muted
          playsInline
        ></video>
      </div>
      <h2 className="text-2xl md:text-3xl font-bold text-center pt-16 md:pt-24 pb-6 md:pb-14">
        METHODS FOR BODY TRANSFORMATION
      </h2>
      <div className="flex flex-col md:flex-row justify-center items-stretch space-y-8 md:space-y-0 md:space-x-8  font-abel ml-[2rem] mr-[2rem]">
        {/* Card 1 */}
        <div className="bg-stone-100 shadow-lg rounded-lg p-4 md:p-8 flex-1 transition-transform duration-300 transform hover:scale-105 hover:bg-gray-200">
          <h3 className="text-lg md:text-xl font-sans font-bold mb-2 md:mb-4">TAILORED TRAINING</h3>
          <p className="mt-2 md:mt-4 text-base md:text-lg text-gray-700">
            Personalized strength training regimen, including compound movements, resistance exercises, and progressive
            overload techniques.
          </p>
        </div>
        {/* Card 2 */}
        <div className="bg-stone-100 shadow-lg rounded-lg p-4 md:p-8 flex-1 transition-transform duration-300 transform hover:scale-105 hover:bg-gray-200">
          <h3 className="text-lg md:text-xl font-sans font-bold mb-2 md:mb-4">NUTRITIONAL GUIDANCE</h3>
          <p className="mt-2 md:mt-4 text-base md:text-lg text-gray-700">
            Comprehensive nutritional plans to support muscle growth and recovery, including macronutrient tracking and
            meal planning.
          </p>
        </div>
        {/* Card 3 */}
        <div className="bg-stone-100 shadow-lg rounded-lg p-4 md:p-8 flex-1 transition-transform duration-300 transform hover:scale-105 hover:bg-gray-200">
          <h3 className="text-lg md:text-xl font-sans font-bold mb-2 md:mb-4">CARDIO WORKOUTS</h3>
          <p className="mt-2 md:mt-4 text-base md:text-lg text-gray-700">
            Incorporates HIIT and steady-state cardio sessions to improve cardiovascular health and endurance.
          </p>
        </div>

        {/* Card 4 */}
        <div className="bg-stone-100 shadow-lg rounded-lg p-4 md:p-8 flex-1 transition-transform duration-300 transform hover:scale-105 hover:bg-gray-200">
          <h3 className="text-lg md:text-xl font-sans font-bold mb-2 md:mb-4">RECOVERY AND MOBILITY</h3>
          <p className="mt-2 md:mt-4 text-base md:text-lg text-gray-700">
            Includes stretching routines, foam rolling, and mobility exercises to prevent injuries and enhance
            performance.
          </p>
        </div>
      </div>
      <div className="h-14 md:h-20"></div>
      {/* WHY AND WHO */}
      <div className="flex justify-center items-center bg-stone-200 ">
        <div className="relative md:h-[40rem] w-full">
          {/* LARGE SCREEN */}
          <div className="relative h-full w-full hidden md:block">
            <img src={image1} alt="Founder" className="w-full h-full object-cover rounded" />
            <div className="absolute inset-0 flex items-center justify-end pr-[17rem]">
              <div className="p-10 rounded-3xl w-[32rem] text-white">
                <h2 className="text-3xl font-bold mt-1 mb-2 ">WHY CHOOSE THIS PROGRAM?</h2>
                <ul className="list-disc pl-5 text-lg mb-6 font-abel text-justify ">
                  <li>Comprehensive fitness approach</li>
                  <li>Personalized plans</li>
                  <li>Sustainable results</li>
                  <li>Expert support</li>
                </ul>
                <div className="h-14"></div>
                <h2 className="text-3xl font-bold mb-2">WHO IS THIS SUITABLE FOR?</h2>
                <ul className="list-disc pl-5 text-lg font-abel text-justify ">
                  <li>Beginners</li>
                  <li>Muscle and strength seekers</li>
                  <li>Those seeking full transformation</li>
                  <li>Structured program enthusiasts</li>
                </ul>
              </div>
            </div>
          </div>

          {/* SMALL SCREEN */}
          <div className="w-full block md:hidden pl-8 pr-8 text-black">
            <h2 className="text-2xl font-bold text-center mb-5">WHY CHOOSE THIS PROGRAM?</h2>
            <div className="bg-stone-100 shadow-lg rounded-lg p-4 transition-transform duration-300 transform hover:scale-105 hover:bg-gray-200 mb-12">
              <ul className="list-disc pl-5 text-lg font-abel text-justify text-gray-700">
                <li>Comprehensive fitness approach</li>
                <li>Personalized plans</li>
                <li>Sustainable results</li>
                <li>Expert support</li>
              </ul>
            </div>
            <h2 className="text-2xl font-bold text-center mb-5">WHO IS THIS SUITABLE FOR?</h2>
            <div className="bg-stone-100 shadow-lg rounded-lg p-4 transition-transform duration-300 transform hover:scale-105 hover:bg-gray-200">
              <ul className="list-disc pl-5 text-lg font-abel text-left text-gray-700">
                <li>Beginners</li>
                <li>Muscle and strength seekers</li>
                <li>Those seeking full transformation</li>
                <li>Structured program enthusiasts</li>
              </ul>
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="h-12 md:h-16"></div>
      {/* CONTACT US */}
      <div className="flex flex-col items-center space-y-4">
        <h2 className=" text-2xl md:text-3xl font-bold text-center">CONTACT US</h2>
        <h2 className="text-lg md:text-xl font-bold text-center font-abel">
          No prefills of forms. <br />
          Say Hi! now to begin the program.
        </h2>{" "}
        <div className="text-center pt-4">
          <a
            href="https://api.whatsapp.com/send?phone=6592312967&text=Hi%20there!%20I%20would%20like%20to%20check%20the%20availability%20for%20a%20trial%20session.%20"
            className="bg-gray-400 hover:bg-gray-300 text-white font-bold py-2 px-4 rounded cursor-pointer font-abel"
            target="_blank"
            rel="noopener noreferrer"
          >
            START NOW
          </a>
        </div>
      </div>
      <div className="h-24"></div>
    </div>
  );
};

export default BodyTransformation;
