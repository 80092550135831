import React, { useEffect, useState, useCallback } from "react";
import api from "../api";

const Payments = ({ isAuthenticated, role }) => {
  const [customers, setCustomers] = useState([]);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("");

  // FETCH CUSTOMERS AND USERS(TRAINERS) DATA
  const fetchData = useCallback(async () => {
    try {
      if (role === "trainer") {
        const customersRes = await api.get("/api/customers");
        setCustomers(customersRes.data);
      } else {
        const [customersRes] = await Promise.all([api.get("/api/customers"), api.get("/api/users")]);
        setCustomers(customersRes.data);
      }
    } catch (err) {
      console.error(err.response.data);
    }
  }, [role]); // Only change when `role` changes

  useEffect(() => {
    fetchData();
  }, [fetchData, isAuthenticated]);

  // Function to group payments by month
  const groupPaymentsByMonth = () => {
    const groupedPayments = {};

    customers.forEach((customer) => {
      customer.paymentHistory.forEach((payment) => {
        const paymentDate = new Date(payment.dateOfPayment);
        const monthYear = paymentDate.toLocaleString("default", {
          month: "long",
          year: "numeric",
        });

        if (!groupedPayments[monthYear]) {
          groupedPayments[monthYear] = [];
        }

        const combinedDateTime = new Date(payment.dateOfPayment);

        groupedPayments[monthYear].push({
          ...payment,
          combinedDateTime,
          name: customer.name,
          email: customer.email,
          trainerName: role === "admin" ? customer.trainerID?.name : null,
        });
      });
    });

    return groupedPayments;
  };

  const groupedPayments = groupPaymentsByMonth();

  // Sorting function for payment data
  const handleSort = (field) => {
    setSortField(field);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  const sortPayments = (payments) => {
    return payments.sort((a, b) => {
      let valueA = a[sortField];
      let valueB = b[sortField];

      if (sortField === "combinedDateTime") {
        valueA = new Date(a.combinedDateTime);
        valueB = new Date(b.combinedDateTime);
      } else if (sortField === "amountPaid") {
        valueA = parseFloat(a.amountPaid);
        valueB = parseFloat(b.amountPaid);
      } else if (sortField === "numOfTrainingsPaidFor") {
        valueA = parseInt(a.numOfTrainingsPaidFor, 10);
        valueB = parseInt(b.numOfTrainingsPaidFor, 10);
      } else if (sortField === "name" || sortField === "email" || sortField === "trainerName") {
        valueA = valueA.toLowerCase();
        valueB = valueB.toLowerCase();
      }

      if (valueA < valueB) {
        return sortOrder === "asc" ? -1 : 1;
      } else if (valueA > valueB) {
        return sortOrder === "asc" ? 1 : -1;
      } else {
        return 0;
      }
    });
  };

  return (
    <div className="w-full min-h-screen pb-24 pl-4 pr-4 md:pl-8 md:pr-8 bg-stone-200">
      <div className="h-6 md:h-12"></div>

      <div className="flex flex-col md:flex-row justify-between items-center pb-4">
        <h3 className="text-lg md:text-xl font-semibold pb-4 md:pb-0">List of Payments (latest 2 months)</h3>
        <p className="text-xs md:text-sm text-gray-500">Click on column headers to sort</p>
      </div>

      {/* Iterate over each month and list payments */}
      {Object.keys(groupedPayments)
        .sort((a, b) => new Date(b) - new Date(a))
        .slice(0, 2)
        .map((monthYear) => (
          <div key={monthYear} className="mb-6">
            <div className="overflow-x-auto">
              <table className="table-fixed w-full border-collapse bg-white shadow-md rounded-lg">
                <thead>
                  <tr className="bg-gray-100 text-gray-700 text-xs md:text-sm">
                    <th
                      className="border-b border-gray-200 px-2 md:px-6 py-3 text-left cursor-pointer w-[15%]"
                      onClick={() => handleSort("combinedDateTime")}
                    >
                      Date {sortField === "combinedDateTime" && (sortOrder === "asc" ? "↑" : "↓")}
                    </th>
                    <th
                      className="border-b border-gray-200 px-2 md:px-6 py-3 text-left cursor-pointer w-[12%]"
                      onClick={() => handleSort("amountPaid")}
                    >
                      $ {sortField === "amountPaid" && (sortOrder === "asc" ? "↑" : "↓")}
                    </th>
                    <th
                      className="border-b border-gray-200 px-2 md:px-6 py-3 text-left cursor-pointer w-[10%]"
                      onClick={() => handleSort("numOfTrainingsPaidFor")}
                    >
                      # {sortField === "numOfTrainingsPaidFor" && (sortOrder === "asc" ? "↑" : "↓")}
                    </th>
                    <th
                      className="border-b border-gray-200 px-2 md:px-6 py-3 text-left cursor-pointer w-[20%]"
                      onClick={() => handleSort("name")}
                    >
                      Name {sortField === "name" && (sortOrder === "asc" ? "↑" : "↓")}
                    </th>
                    <th
                      className="border-b border-gray-200 px-2 md:px-6 py-3 text-left cursor-pointer w-[20%]"
                      onClick={() => handleSort("email")}
                    >
                      Email {sortField === "email" && (sortOrder === "asc" ? "↑" : "↓")}
                    </th>
                    {role === "admin" && (
                      <th
                        className="border-b border-gray-200 px-2 md:px-6 py-3 text-left cursor-pointer w-[20%]"
                        onClick={() => handleSort("trainerName")}
                      >
                        Trainer {sortField === "trainerName" && (sortOrder === "asc" ? "↑" : "↓")}
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody className="text-xs md:text-sm">
                  {sortPayments(groupedPayments[monthYear]).map((payment, index) => (
                    <tr key={index} className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}>
                      <td className="px-2 md:px-6 py-3">
                        {new Date(payment.combinedDateTime).toLocaleString([], {
                          year: "2-digit",
                          month: "short",
                          day: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </td>
                      <td className="px-2 md:px-6 py-3">${payment.amountPaid}</td>
                      <td className="px-2 md:px-6 py-3">{payment.numOfTrainingsPaidFor}</td>
                      <td className="px-2 md:px-6 py-3">{payment.name}</td>
                      <td className="px-2 md:px-6 py-3 break-words whitespace-normal w-1/4">{payment.email}</td>

                      {role === "admin" && <td className="px-2 md:px-6 py-3">{payment.trainerName || "N/A"}</td>}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Payments;
