import React from "react";
import { NavLink } from "react-router-dom";

const CTAbutton = ({ page, title }) => {
  return (
    <div>
      <NavLink
        to={`/${page}`}
        className="bg-gray-400 hover:bg-gray-300 text-white font-extrabold py-2 px-4 rounded cursor-pointer font-abel text-sm md:text-base md:py-2 md:px-4"
      >
        {title.toUpperCase()}
      </NavLink>
    </div>
  );
};

export default CTAbutton;
