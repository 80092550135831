import React from "react";
import videoSrc from "../assets/Homepage/hero.mp4";
import SubHeroSection from "./SubHeroSection";
import GymSection from "./GymSection";
import TrialSection from "./TrialSection";
import FounderSection from "./FounderSection";
import TestimonialSection from "./TestimonialSection";
import { Link } from "react-scroll";

const Homepage = () => {
  return (
    <div className="h-screen w-screen bg-stone-300">
      <div className="relative h-[82%] overflow-hidden shadow-2xl ">
        <video
          className="absolute top-0 left-0 w-full h-full object-cover"
          src={videoSrc}
          autoPlay
          loop
          muted
          playsInline
        ></video>
        <div className="relative z-10 flex flex-col items-start justify-center h-full text-left text-white pl-8">
          <h1 className="text-2xl md:text-4xl font-bold mb-4 md:ml-10">ACHIEVE YOUR BODY TRANSFORMATION</h1>
          <p className="text-xl md:text-2xl mb-8 font-abel md:ml-10">
            No Experience Required - Look & Feel Amazing with Our Programs
          </p>
          <Link
            to="what-to-expect"
            smooth={true}
            duration={500}
            offset={-30}
            className="bg-gray-400  hover:bg-gray-300 text-white font-bold py-2 px-4 rounded cursor-pointer font-abel md:ml-10"
          >
            GET STARTED
          </Link>
        </div>
      </div>{" "}
      <GymSection />
      <SubHeroSection />
      <TestimonialSection />
      <FounderSection />
      <div id="what-to-expect">
        <TrialSection />
      </div>
    </div>
  );
};

export default Homepage;
