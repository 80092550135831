import React, { useEffect } from "react";

const TrainerForm = ({ onSubmit, formData, onChange, setTrainerForm, setNotification, trainerForm }) => {
  useEffect(() => {
    if (trainerForm) {
      // Add a class to the body to prevent scrolling
      document.body.style.overflow = "hidden";
    } else {
      // Remove the class when the form is closed
      document.body.style.overflow = "auto";
    }

    // Cleanup the effect when the component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [trainerForm]);

  return (
    <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="relative bg-white p-14 rounded-lg shadow-lg flex flex-col items-center">
        <button
          className="absolute top-10 right-10"
          onClick={() => {
            setTrainerForm(false);
            setNotification(false);
          }}
        >
          x
        </button>

        <form onSubmit={onSubmit} className="space-y-4">
          <h3 className="text-xl font-semibold mb-6">Add a New Trainer</h3>
          <div className="mb-4">
            <label className="block font-bold text-sm mb-2 pl-2">NAME</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={onChange}
              required
              className="w-full p-2 border border-gray-300 rounded pl-2"
            />
          </div>
          <div className="mb-4">
            <label className="block font-bold text-sm mb-2 pl-2">EMAIL</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={onChange}
              required
              className="w-full p-2 border border-gray-300 rounded pl-2"
            />
          </div>
          <div className="mb-4">
            <label className="block font-bold text-sm mb-2 pl-2">PASSWORD</label>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={onChange}
              required
              className="w-full p-2 border border-gray-300 rounded pl-2"
            />
          </div>
          <div className="mb-4">
            <label className="block font-bold text-sm mb-2 pl-2">ROLE</label>
            <select
              name="role"
              value={formData.role}
              onChange={onChange}
              required
              className="w-full p-2 border border-gray-300 rounded pl-2 bg-white appearance-none h-11"
            >
              <option value="">Select from dropdown</option>
              <option value="trainer">Trainer</option>
              <option value="admin">Admin</option>
            </select>
          </div>
          <div>
            <button className="text-white bg-black font-medium mt-4 p-1 pl-2 pr-2 rounded-xl">Add</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TrainerForm;
