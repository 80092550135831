import React from "react";
import testimonial1 from "../assets/TestimonialSection/testimonial1.png";
import testimonial2 from "../assets/TestimonialSection/testimonial2.png";
import testimonial3 from "../assets/TestimonialSection/testimonial3.png";
import image1 from "../assets/TestimonialSection/1.png";
import image2 from "../assets/TestimonialSection/2.png";
import image3 from "../assets/TestimonialSection/3.png";
import image4 from "../assets/TestimonialSection/4.png";
import image5 from "../assets/TestimonialSection/5.png";
import image6 from "../assets/TestimonialSection/6.png";
import image7 from "../assets/TestimonialSection/7.png";
import image8 from "../assets/TestimonialSection/8.png";
import image9 from "../assets/TestimonialSection/9.png";
import image10 from "../assets/TestimonialSection/10.png";
import image11 from "../assets/TestimonialSection/11.png";

const TestimonialSection = () => {
  return (
    <div className="bg-stone-200 md:px-8 pt-20 md:pt-0 pb-16 md:pb-20">
      <h2 className="text-2xl md:text-3xl font-bold text-center">TRAIN WITH CONFIDENCE KNOWING THE RESULTS</h2>

      <div className="flex flex-col md:grid md:grid-cols-3 md:gap-4  justify-center items-stretch md:space-y-8  font-abel">
        {/* Testimonial 1 */}
        <div className="bg-stone-200 rounded-lg p-2  flex-1 flex justify-center items-center">
          <img
            src={testimonial1}
            alt="Before and After"
            className="w-[22rem] h-[16rem] md:w-[30rem] md:h-[22rem] rounded-md md:mt-[2rem] md:mb-4"
          />
        </div>
        {/* Testimonial 2 */}
        <div className="bg-stone-200 rounded-lg p-2  flex-1 flex justify-center items-center">
          <img
            src={testimonial2}
            alt="Before and After"
            className="rounded-md md:mb-4  w-[22rem] h-[16rem] md:w-[30rem] md:h-[22rem] "
          />
        </div>
        {/* Testimonial 3 */}
        <div className="bg-stone-200 rounded-lg p-2  flex-1 flex justify-center items-center">
          <img
            src={testimonial3}
            alt="Before and After"
            className="rounded-md  md:mb-4  w-[22rem] h-[16rem] md:w-[30rem] md:h-[22rem] "
          />
        </div>
        {/* Before & After */}
        <div className="bg-stone-200 rounded-lg p-2  flex-1 flex justify-center items-center">
          <img
            src={image1}
            alt="Before and After"
            className="w-[22rem] h-[16rem] md:w-[30rem] md:h-[22rem] rounded-md"
          />
        </div>
        <div className="bg-stone-200 rounded-lg p-2  flex-1 flex justify-center items-center">
          <img
            src={image2}
            alt="Before and After"
            className="w-[22rem] h-[16rem] md:w-[30rem] md:h-[22rem] rounded-md"
          />
        </div>
        <div className="bg-stone-200 rounded-lg p-2  flex-1 flex justify-center items-center">
          <img
            src={image3}
            alt="Before and After"
            className="w-[22rem] h-[16rem] md:w-[30rem] md:h-[22rem] rounded-md"
          />
        </div>
        <div className="bg-stone-200 rounded-lg p-2  flex-1 flex justify-center items-center">
          <img
            src={image4}
            alt="Before and After"
            className="w-[22rem] h-[16rem] md:w-[30rem] md:h-[22rem] rounded-md"
          />
        </div>
        {/* added flex items-center justify-center to the div's class names. This makes the div a flex container and centers
        its content (the image) both vertically (items-center) and horizontally (justify-center). */}
        <div className="bg-stone-200 rounded-lg p-2 flex-1 flex items-center justify-center">
          <img
            src={image5}
            alt="Before and After"
            className="w-[22rem] h-[16rem] md:w-[30rem] md:h-[20rem] rounded-md"
          />
        </div>
        {/* To center image middle of div: flex is added to the div to enable flexbox layout. justify-center centers the
        image horizontally. items-center centers the image vertically. */}
        <div className="bg-stone-200 rounded-lg p-2 flex-1 flex justify-center items-center">
          <img
            src={image6}
            alt="Before and After"
            className="w-[18rem] h-[16rem] md:w-[23rem] md:h-[22rem] rounded-md"
          />
        </div>
        <div className="bg-stone-200 rounded-lg p-2  flex-1 flex justify-center items-center">
          <img
            src={image7}
            alt="Before and After"
            className="w-[22rem] h-[16rem] md:w-[30rem] md:h-[22rem] rounded-md"
          />
        </div>
        {/* To center image middle of div: flex is added to the div to enable flexbox layout. justify-center centers the
        image horizontally. items-center centers the image vertically. */}
        <div className="bg-stone-200 rounded-lg p-2 flex-1 flex justify-center items-center">
          <img
            src={image8}
            alt="Before and After"
            className="w-[18rem] h-[16rem] md:w-[23rem] md:h-[22rem] rounded-md"
          />
        </div>
        <div className="bg-stone-200 rounded-lg p-2  flex-1 flex justify-center items-center">
          <img
            src={image9}
            alt="Before and After"
            className="w-[22rem] h-[16rem] md:w-[30rem] md:h-[22rem] rounded-md"
          />
        </div>
        <div className="bg-stone-200 rounded-lg p-2  flex-1 flex justify-center items-center">
          <img
            src={image10}
            alt="Before and After"
            className="w-[22rem] h-[16rem] md:w-[30rem] md:h-[22rem] rounded-md"
          />
        </div>
        <div className="bg-stone-200 rounded-lg p-2  flex-1 flex justify-center items-center">
          <img
            src={image11}
            alt="Before and After"
            className="w-[22rem] h-[16rem] md:w-[30rem] md:h-[22rem] rounded-md"
          />
        </div>
      </div>
    </div>
  );
};

export default TestimonialSection;
