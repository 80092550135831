import React, { useState, useEffect } from "react";
import api from "../api";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const Profile = () => {
  const [profile, setProfile] = useState({ name: "", email: "" });
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [updateError, setUpdateError] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordMismatch, setPasswordMismatch] = useState(false);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const { data } = await api.get("/api/profile", {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        });
        setProfile(data);
      } catch (error) {
        console.error("Error fetching profile", error);
      }
    };
    fetchProfile();
  }, []);

  const handlePasswordChange = async () => {
    if (newPassword !== confirmPassword) {
      setPasswordMismatch(true);
      return;
    }

    setPasswordMismatch(false);
    try {
      await api.put(
        "/api/profile/password",
        { oldPassword, newPassword },
        { headers: { Authorization: `Bearer ${localStorage.getItem("token")}` } }
      );
      setUpdateSuccess(true);
      setUpdateError(false);
      setShowChangePassword(false);
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      setUpdateError(true);
      setUpdateSuccess(false);
    }
  };

  const formattedDate = profile.dateCreated ? format(new Date(profile.dateCreated), "EEEE, MMMM do, yyyy h:mm a") : "";

  return (
    <div className="flex justify-center min-h-screen bg-stone-200">
      <div className="p-6 rounded-lg w-full max-w-sm">
        <h2 className="text-xl font-bold  text-center">Profile</h2>
        <div className="pt-3 space-y-6 pb-6">
          <p className="w-full ">Name: {profile.name}</p>
          <p className="w-full ">Email: {profile.email}</p>
          <p className="w-full ">Date Created: {formattedDate}</p>
          <div className="flex items-center justify-left">
            <button
              onClick={() => setShowChangePassword(true)}
              className="text-white bg-black font-medium text-sm px-5 py-1 rounded"
            >
              Change Password
            </button>
          </div>
        </div>
        {showChangePassword && (
          <div className="mt-2 border-t border-gray-400 pt-6">
            <div className="mb-4">
              <label className="block font-bold text-sm mb-2">Old Password</label>
              <div className="relative">
                <input
                  type={showOldPassword ? "text" : "password"}
                  placeholder="Old Password"
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                  className="w-full p-1 pl-2 border border-gray-300 rounded placeholder-gray-500 placeholder:text-sm placeholder:font-medium"
                />
                <button
                  type="button"
                  className="absolute right-2 top-1"
                  onClick={() => setShowOldPassword(!showOldPassword)}
                >
                  <FontAwesomeIcon icon={showOldPassword ? faEyeSlash : faEye} className="opacity-30 text-sm " />
                </button>
              </div>
            </div>

            <div className="mb-4">
              <label className="block font-bold text-sm mb-2">New Password</label>
              <div className="relative">
                <input
                  type={showNewPassword ? "text" : "password"}
                  placeholder="New Password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="w-full p-1 pl-2 border border-gray-300 rounded placeholder-gray-500 placeholder:text-sm placeholder:font-medium"
                />
                <button
                  type="button"
                  className="absolute right-2 top-1"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                >
                  <FontAwesomeIcon icon={showNewPassword ? faEyeSlash : faEye} className="opacity-30 text-sm " />
                </button>
              </div>
            </div>

            <div className="mb-6">
              <label className="block font-bold text-sm mb-2">Confirm New Password</label>
              <div className="relative">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm New Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="w-full p-1 pl-2 border border-gray-300 rounded placeholder-gray-500 placeholder:text-sm placeholder:font-medium"
                />
                <button
                  type="button"
                  className="absolute right-2 top-1"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} className="opacity-30 text-sm " />
                </button>
              </div>
            </div>

            {passwordMismatch && (
              <p className="mb-4 text-center text-red-400">The new passwords do not match. Please try again.</p>
            )}

            <div className="flex items-center justify-center">
              <button
                onClick={handlePasswordChange}
                className="text-white bg-black font-medium text-sm px-5 py-1 rounded"
              >
                Submit
              </button>
              <button
                onClick={() => setShowChangePassword(false)}
                className="ml-4 text-sm text-gray-500 hover:underline"
              >
                Cancel
              </button>
            </div>
          </div>
        )}{" "}
        {updateSuccess && <p className="mt-4 text-center text-green-400">Password updated successfully!</p>}
        {updateError && <p className="mt-4 text-center text-red-400">Error updating password. Please try again.</p>}
      </div>
    </div>
  );
};

export default Profile;
