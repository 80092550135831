import api from "../api";
import React, { useEffect, useState, useCallback } from "react";

import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const TrainerDashboard = () => {
  const [unclaimedTrainings, setUnclaimedTrainings] = useState([]);
  const [notification, setNotification] = useState("");
  const [completedTrainingsByMonth, setCompletedTrainingsByMonth] = useState({});
  const [displayTable, setDisplayTable] = useState(false);

  // FETCH ALL UNCLAIMED TRAININGS
  const fetchUnclaimedTrainings = async () => {
    try {
      const res = await api.get("/api/unclaimedtraining");
      console.log(res.data);
      if (res.data.length > 0) {
        setDisplayTable(true);
      } else {
        setDisplayTable(false);
      }
      setUnclaimedTrainings(res.data);
    } catch (err) {
      console.error(err.response.data);
    }
  };

  // FETCH ALL TRAININGS
  const fetchTrainings = useCallback(async () => {
    try {
      const res = await api.get("/api/training");
      console.log("All Trainings", res);

      countCompletedTrainingsByMonth(res.data); // Count completed trainings after fetching
    } catch (error) {
      console.error("Error fetching trainings", error);
    }
  }, []);

  // USE EFFECT
  useEffect(() => {
    fetchUnclaimedTrainings();
    fetchTrainings();
  }, [fetchTrainings]);

  // USE EFFECT TO AUTO-CLOSE NOTIFICATION
  useEffect(() => {
    if (notification) {
      const timeout = setTimeout(() => {
        setNotification("");
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [notification]);

  // COUNT AND GROUP COMPLETED TRAININGS
  const countCompletedTrainingsByMonth = (trainings) => {
    const completedTrainings = trainings.filter((training) => training.status === "completed");

    // Reduce to group by month and year
    const counts = completedTrainings.reduce((acc, training) => {
      const date = new Date(training.dateOfTraining);
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // getMonth() returns 0-indexed months, so add 1
      const monthYear = `${year}-${String(month).padStart(2, "0")}`; // Format as YYYY-MM

      // Initialize or increment the count for this month
      acc[monthYear] = (acc[monthYear] || 0) + 1;

      return acc;
    }, {});
    // Sort the counts by the formatted monthYear string
    const sortedCounts = Object.keys(counts)
      .sort()
      .reduce((acc, key) => {
        acc[key] = counts[key];
        return acc;
      }, {});
    setCompletedTrainingsByMonth(sortedCounts);
  };

  // CLAIM A TRAINING
  const claimTraining = async (e) => {
    try {
      await api.post(`/api/claimtraining/${e}`, { status: "completed" });
      setNotification("Success! Training has been changed to claimed and completed.");
      fetchUnclaimedTrainings();
      fetchTrainings();
    } catch (err) {
      console.error(err.response.data);
      setNotification("Failed to claim, please try again.");
    }
  };

  // DELETE A TRAINING
  const deleteTraining = async (e) => {
    try {
      await api.delete(`/api/training/${e}`);
      setNotification("Success! Training has been deleted.");
      fetchUnclaimedTrainings();
    } catch (err) {
      console.error(err.response.data);
      setNotification("Failed to delete, please try again.");
    }
  };

  // DATA FOR LINE CHART
  const lineChartData = {
    labels: Object.keys(completedTrainingsByMonth),
    datasets: [
      {
        label: "Completed Trainings",
        data: Object.values(completedTrainingsByMonth),
        borderColor: "#4CAF50",
        backgroundColor: "rgba(76, 175, 80, 0.2)",
        fill: true,
      },
    ],
  };

  const lineChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Completed Trainings by Month",
      },
    },
  };

  return (
    <div className="w-full min-h-screen bg-stone-200 pb-32 md:pb-0">
      <div className="h-4 md:h-10"></div>{" "}
      {notification && (
        <div className="mb-4 p-4 text-white bg-green-500 rounded flex justify-between items-center">
          <span>{notification}</span>
          <button onClick={() => setNotification("")} className="ml-4">
            ✖
          </button>
        </div>
      )}
      <div className="w-[90%] md:flex-row flex flex-col md:space-x-4">
        <div className="w-full md:w-1/2 md:pl-10">
          <label className="pl-8 block font-bold text-lg md:text-xl mb-2">Total Completed Trainings by Month:</label>
          <div className="w-full pl-8" style={{}}>
            <Line data={lineChartData} options={lineChartOptions} />
          </div>
        </div>
        <div className="h-10"></div>

        {displayTable ? (
          <div className="w-full md:w-1/2 pl-8">
            <label className="block font-bold text-lg md:text-xl mb-2">Review Unclaimed Trainings:</label>
            <div className="w-full text-sm md:text-base">
              <table className="table-auto border-collapse border border-black divide-y divide-black text-left">
                <thead>
                  <tr>
                    <th className="p-4 w-[50%]">Customer</th>
                    <th className="p-4 w-[25%]">Claim </th>
                    <th className="p-4 w-[25%]">Delete </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-black">
                  {unclaimedTrainings?.map((unclaimedTraining) => {
                    // Format the date and time
                    const formattedDate = new Date(unclaimedTraining?.dateOfTraining).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    });

                    const formattedTime = new Date(unclaimedTraining?.dateOfTraining).toLocaleTimeString("en-US", {
                      hour: "2-digit",
                      minute: "2-digit",
                      hour12: true,
                    });

                    return (
                      <tr key={unclaimedTraining?._id}>
                        <td className="p-2">
                          {unclaimedTraining?.customerID?.name}
                          <br />
                          {`${formattedDate}, ${formattedTime}`}
                          <br />
                        </td>
                        <td className="p-2 text-center">
                          <button
                            className="bg-green-400 p-1 pl-2 pr-2 rounded-xl text-white hover:scale-110 transition-transform duration-500"
                            onClick={() => claimTraining(unclaimedTraining?._id)}
                          >
                            Claim
                          </button>
                        </td>
                        <td className="p-2 text-center">
                          <button
                            className="bg-indigo-400 p-1 pl-2 pr-2 rounded-xl text-white hover:scale-110 transition-transform duration-500"
                            onClick={() => deleteTraining(unclaimedTraining?._id)}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <div className="h-8"></div>
          </div>
        ) : (
          <div>
            <label className="pl-8 block font-bold text-lg md:text-xl mb-2">
              Review Unclaimed Trainings: No unclaimed trainings found in records.
            </label>
          </div>
        )}
      </div>
    </div>
  );
};

export default TrainerDashboard;
