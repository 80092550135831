import React, { useEffect } from "react";

const CustomerForm = ({
  setNotification,
  formData,
  onChange,
  onSubmit,
  trainers,
  setCustomerForm,
  customerForm,
  role,
  selectedTrainerID,
  setSelectedTrainerID,
}) => {
  useEffect(() => {
    if (customerForm) {
      // Prevent scrolling of the page when the form is open
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    // Cleanup the effect when the component unmounts
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [customerForm]);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="relative bg-white p-14 rounded-lg shadow-lg flex flex-col  items-center max-h-[90vh] overflow-y-auto">
        <button
          className="absolute top-10 right-10"
          onClick={() => {
            setCustomerForm(false);
            setNotification(false);
          }}
        >
          x
        </button>

        <form onSubmit={onSubmit} className="space-y-4">
          <div className="mb-4">
            <label className="block font-bold text-sm mb-2 pl-2">NAME</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={onChange}
              required
              className="w-full p-2 border border-gray-300 rounded pl-4"
            />
          </div>
          <div className="mb-4">
            <label className="block font-bold text-sm mb-2 pl-2">EMAIL</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={onChange}
              // placeholder="Email"
              required
              className="w-full p-2 border border-gray-300 rounded pl-4"
            />
          </div>
          <div className="mb-4">
            <label className="block font-bold text-sm mb-2 pl-2">PHONE</label>
            <input
              type="number"
              name="phone"
              value={formData.phone}
              onChange={onChange}
              required
              className="w-full p-2 border border-gray-300 rounded pl-4"
            />
          </div>
          {/* //=============== Inserts grid to customize layout of form's fields. The PHONE, DOB, IC, and SEX fields are placed inside a grid with two columns (grid-cols-2) and some gap (gap-4) between them. */}
          <div className="grid grid-cols-2 gap-4">
            <div className="mb-4">
              <label className="block font-bold text-sm mb-2 pl-2">Date of Birth</label>
              <input
                type="date"
                name="dateOfBirth"
                value={formData.dateOfBirth}
                onChange={onChange}
                required
                className="w-full p-2 border border-gray-300 rounded pl-0 md:pl-3 bg-white appearance-none h-10"
              />
            </div>

            <div className="mb-4">
              <label className="block font-bold text-sm mb-2 pl-2">IC</label>
              <input
                type="text"
                name="ic"
                value={formData.ic}
                onChange={onChange}
                required
                className="w-full p-2 border border-gray-300 rounded pl-4"
              />
            </div>
            <div className="mb-4">
              <label className="block font-bold text-sm mb-2 pl-2">SEX</label>
              <input
                type="text"
                name="sex"
                value={formData.sex}
                onChange={onChange}
                required
                className="w-full p-2 border border-gray-300 rounded pl-4"
              />
            </div>
            {role === "admin" ? (
              <div className="mb-4">
                <label className="block font-bold text-sm mb-2 pl-2">TRAINER</label>
                <select
                  name="trainerID"
                  value={selectedTrainerID}
                  onChange={(e) => setSelectedTrainerID(e.target.value)}
                  required
                  className="w-full p-2 border border-gray-300 rounded pl-2 bg-white appearance-none h-10.5"
                >
                  <option value="">Select Trainer</option>
                  {trainers.map((trainer) => (
                    <option key={trainer._id} value={trainer._id}>
                      {trainer.name}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <div className="h-4"></div>
            )}

            <div className="">
              <button className="text-white bg-black font-medium mt-2 p-1 pl-2 pr-2 rounded-xl">Add</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CustomerForm;
