import React, { useEffect, useState, useCallback, useMemo } from "react";
import api from "../api";
import Modal from "react-modal";
import TrainingCalendar from "./TrainingCalendar";
import TrainingStats from "./TrainingStats";
import TrainingModal from "./TrainingModal";

// Set the app element for accessibility
// By passing #root to Modal.setAppElement(), you're indicating that the entire application should be hidden from screen readers when the modal is open, focusing solely on the modal content
Modal.setAppElement("#root");

const Trainings = ({ user, role }) => {
  const [customers, setCustomers] = useState([]);
  const [trainings, setTrainings] = useState([]);
  const [filteredTrainings, setFilteredTrainings] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [formData, setFormData] = useState({ customerID: "", date: "", status: "scheduled" });
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [selectedTrainer, setSelectedTrainer] = useState("");
  const [editTraining, setEditTraining] = useState(null);
  const [error, setError] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [trainingStats, setTrainingStats] = useState(null);
  const [trainers, setTrainers] = useState([]);
  const [notification, setNotification] = useState("");
  const now = useMemo(() => new Date(), []);
  const past90Days = useMemo(() => {
    const date = new Date(now);
    date.setDate(date.getDate() - 90);
    return date;
  }, [now]);
  past90Days.setDate(past90Days.getDate() - 90);

  // FETCH CUSTOMERS AND TRAININGS
  const fetchCustomers = async () => {
    try {
      const res = await api.get("/api/customers");
      // console.log("All Customers", res);
      setCustomers(res.data);
    } catch (error) {
      console.error("Error fetching customers", error);
    }
  };

  const fetchTrainers = async () => {
    try {
      const res = await api.get("/api/users");
      console.log("All Trainers", res);
      setTrainers(res.data);
    } catch (error) {
      console.error("Error fetching trainers", error);
    }
  };

  const fetchTrainings = async () => {
    try {
      const res = await api.get("/api/training");
      //console.log("All Trainings", res);
      setTrainings(res.data);
    } catch (error) {
      console.error("Error fetching trainings", error);
    }
  };

  // CALL FUNCTIONS UPON LOADING OF PAGE
  useEffect(() => {
    if (user) {
      fetchCustomers();
      fetchTrainings();
      // Check if the user is an admin before calling fetchTrainers
      if (user.role === "admin") {
        fetchTrainers();
      }
    }
  }, [isModalOpen, user]);

  // CALCULATE STATS FOR FILTERED TRAININGS
  const calculateTrainingStats = useCallback(
    (filteredTrainings) => {
      let scheduledCounter = 0;
      let completedCounter = 0;
      const next90Days = new Date(now);
      next90Days.setDate(next90Days.getDate() + 90);
      const pastSessionsLast90Days = filteredTrainings.filter((training) => {
        const trainingDate = new Date(training.dateOfTraining);
        const isWithinPast90Days = trainingDate >= past90Days && trainingDate < now;
        if (isWithinPast90Days) {
          if (training.status === "scheduled") {
            scheduledCounter++;
          } else if (training.status === "completed") {
            completedCounter++;
          }
        }
        return isWithinPast90Days;
      });
      const upcomingSessionsNext90Days = filteredTrainings.filter(
        (training) => new Date(training.dateOfTraining) >= now && new Date(training.dateOfTraining) <= next90Days
      );
      const avgPastSessionsPerWeek = pastSessionsLast90Days.length / 8.57; // 90 days / 7 days per week = 8.57 weeks
      setTrainingStats({
        pastCount: pastSessionsLast90Days.length,
        upcomingCount: upcomingSessionsNext90Days.length,
        avgPastSessionsPerWeek: avgPastSessionsPerWeek.toFixed(2),
        scheduledCount: scheduledCounter,
        completedCount: completedCounter,
      });
    },
    [now, past90Days]
  );

  // FILTERS
  // Update customers when the selected trainer changes
  useEffect(() => {
    if (selectedTrainer) {
      setFilteredCustomers(customers.filter((customer) => customer.trainerID._id === selectedTrainer));

      const filtered = trainings.filter((training) => training.trainerID?._id === selectedTrainer);
      console.log("FILTERED TRAININGS", filtered);
      setFilteredTrainings(filtered);
    } else {
      setFilteredCustomers(customers); // Show all customers if no trainer is selected
    }
  }, [selectedTrainer, customers]);

  // Update training stats and filtered trainings when a customer is selected
  useEffect(() => {
    if (selectedCustomer) {
      console.log("SELECTED CUSTOMER", selectedCustomer); // prints SELECTED CUSTOMER 6715f195ead5fe2f13a4ce47
      const filtered = trainings.filter((training) => training.customerID?._id === selectedCustomer);
      setFilteredTrainings(filtered);
      calculateTrainingStats(filtered);
    } else {
      setFilteredTrainings(trainings);
      setTrainingStats(null);
    }
  }, [selectedCustomer, trainings, calculateTrainingStats]);

  // CLEAR FILTERS FUNCTION
  const clearFilters = () => {
    setSelectedTrainer("");
    setSelectedCustomer("");
  };

  // FORMAT DATE yyyy-MM-ddThh:mm
  const formatDate = (date) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = `0${d.getMonth() + 1}`.slice(-2);
    const day = `0${d.getDate()}`.slice(-2);
    const hours = `0${d.getHours()}`.slice(-2);
    const minutes = `0${d.getMinutes()}`.slice(-2);
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  // USE EFFECT TO AUTO-CLOSE NOTIFICATION
  useEffect(() => {
    if (notification) {
      const timeout = setTimeout(() => {
        setNotification("");
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [notification]);

  // EDIT A TRAINING - ok admin
  const handleEventClick = (arg) => {
    const training = trainings.find((training) => training._id === arg.event.id);
    setEditTraining(training);
    setFormData({
      customerID: training.customerID._id,
      date: formatDate(training.dateOfTraining),
      status: training.status,
    });
    setIsModalOpen(true);
  };

  // HANDLE INPUT CHANGE
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // SUBMITS A NEW OR EDITS TO A TRAINING - ok admin
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      const startDate = new Date(formData.date);
      const endDate = new Date(startDate);
      endDate.setHours(startDate.getHours() + 1);
      // Find the selected customer to get the associated trainerID
      const selectedCustomer = customers.find((customer) => customer._id === formData.customerID);
      const trainerID = selectedCustomer ? selectedCustomer.trainerID : user._id;
      let response;
      if (editTraining) {
        // Update an existing training session
        response = await api.put(`/api/training/${editTraining._id}`, {
          ...formData,
          dateOfTraining: formData.date,
          trainerID,
        });
      } else {
        // Create a new training session
        response = await api.post("/api/training", {
          ...formData,
          dateOfTraining: formData.date,
          trainerID,
        });
      }
      // If editing an existing training, it updates the specific training in the trainings state using map. It replaces the old training with the updated one returned in the response.
      setTrainings((prevSessions) =>
        editTraining
          ? prevSessions.map((training) => (training._id === editTraining._id ? response?.data : training))
          : [...prevSessions, response?.data]
      );
      setEditTraining(null);
      setFormData({ customerID: "", date: "", status: "scheduled" });
      setIsModalOpen(false);
    } catch (error) {
      setError(error.response?.data.error);
      console.error("Error scheduling training", error);
    }
  };

  // DELETES A TRAINING - ok admin
  const handleDelete = async () => {
    try {
      if (editTraining) {
        await api.delete(`/api/training/${editTraining._id}`);
        setTrainings((prevSessions) => prevSessions.filter((training) => training._id !== editTraining._id));
        setEditTraining(null);
        setFormData({ customerID: "", date: "", status: "scheduled" });
        setIsModalOpen(false);
      }
    } catch (error) {
      console.error("Error deleting training", error);
    }
  };

  // BUTTON: CLAIM A TRAINING - ok admin
  const claimTraining = async (e) => {
    try {
      await api.post(`/api/claimtraining/${e}`, { status: "completed" });
      setNotification("Success! Training has been changed to claimed and completed.");
      fetchCustomers();
      fetchTrainings();
    } catch (err) {
      console.error(err.response.data);
      setNotification("Failed to claim, please try again.");
    }
  };

  // BUTTON: DELETE A TRAINING
  const deleteTraining = async (e) => {
    try {
      await api.delete(`/api/training/${e}`);
      setNotification("Success! Training has been deleted.");
      fetchCustomers();
      fetchTrainings();
    } catch (err) {
      console.error(err.response.data);
      setNotification("Failed to delete, please try again.");
    }
  };

  return (
    <div className="w-full min-h-screen pb-24 pl-8 pr-8 bg-stone-200">
      <div className="h-6 md:h-12"></div>
      {/* NOTIFICATION */}
      {notification && (
        <div className="mb-4 p-4 text-white bg-green-500 rounded flex justify-between items-center">
          <span>{notification}</span>
          <button onClick={() => setNotification("")} className="ml-4">
            ✖
          </button>
        </div>
      )}
      {error && <div className="text-red-500 mb-4">{error}</div>}
      {/* FILTER BY CUSTOMER */}
      <label className="md:w-[42%] block font-semibold text-lg md:text-xl mb-2 pt-1">
        Filters for Calendar and Training Stats
      </label>
      <div className="mb-4 md:flex-row flex-col flex space-y-4 md:space-y-0 ">
        {role === "admin" && (
          <div className="md:w-1/3 md:pr-2">
            <label className="block mb-1 text-sm text-gray-500">Select Trainer</label>
            <select
              value={selectedTrainer}
              onChange={(e) => setSelectedTrainer(e.target.value)}
              className="w-full p-2 border border-gray-300 rounded-lg"
            >
              <option value="">All Trainers</option>
              {trainers.map((trainer) => (
                <option key={trainer._id} value={trainer._id}>
                  {trainer.name}
                </option>
              ))}
            </select>
          </div>
        )}
        <div className="md:w-1/3 md:pr-2">
          <label className="block mb-1 text-sm text-gray-500">
            Select Customer {user.role === "admin" && "(Filtered by Trainer)"}
          </label>

          <select
            value={selectedCustomer}
            onChange={(e) => setSelectedCustomer(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-lg"
          >
            <option value="">All Customers</option>
            {filteredCustomers.map((customer) => (
              <option key={customer._id} value={customer._id}>
                {customer.name}
              </option>
            ))}
          </select>
        </div>{" "}
      </div>
      {/* Clear Filters Button */}
      <div className="pb-8">
        <button
          onClick={clearFilters}
          className="bg-blue-500 text-white px-2 py-2 rounded-xl hover:bg-blue-600 transition-colors text-xs md:text-sm"
        >
          Clear Filters
        </button>
      </div>{" "}
      {/* TABLE: TRAINING STATS */}
      <TrainingStats
        filteredTrainings={filteredTrainings}
        selectedCustomer={selectedCustomer}
        claimTraining={claimTraining}
        deleteTraining={deleteTraining}
        formatDate={formatDate}
        setEditTraining={setEditTraining}
        setFormData={setFormData}
        setIsModalOpen={setIsModalOpen}
        now={now}
        past90Days={past90Days}
        trainingStats={trainingStats}
      />
      <h3 className="text-lg md:text-xl font-semibold pb-2">Schedule/Edit/Delete Training</h3>
      <TrainingCalendar
        setFormData={setFormData}
        setIsModalOpen={setIsModalOpen}
        formatDate={formatDate}
        handleEventClick={handleEventClick}
        now={now}
        formData={formData}
        filteredTrainings={filteredTrainings}
      />
      <TrainingModal
        setIsModalOpen={setIsModalOpen}
        isModalOpen={isModalOpen}
        setEditTraining={setEditTraining}
        setFormData={setFormData}
        formData={formData}
        customers={customers}
        user={user}
        handleSubmit={handleSubmit}
        handleInputChange={handleInputChange}
        editTraining={editTraining}
        handleDelete={handleDelete}
        trainers={trainers}
      />
    </div>
  );
};

export default Trainings;
